/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import { ResourceBody } from "@5gmediahub-platform/types";
import { Buffer } from "buffer";
import ClientRepresentation from "@keycloak/keycloak-admin-client/lib/defs/clientRepresentation";
import CredentialRepresentation from "@keycloak/keycloak-admin-client/lib/defs/credentialRepresentation";
import GroupRepresentation from "@keycloak/keycloak-admin-client/lib/defs/groupRepresentation";
import ResourceRepresentation from "@keycloak/keycloak-admin-client/lib/defs/resourceRepresentation";
import { useKeycloak } from "@react-keycloak/web";
import { AxiosError } from "axios";
import {
  Box, CheckBox, Heading, TableBody, TableCell, Tab as TabComponent, Spinner,
} from "grommet";
import { useInfiniteQuery, useQueryClient } from "react-query";
import Prism from "prismjs";
import "prismjs/components/prism-json";
import {
  SetStateAction, useEffect, useRef, useState
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../components/Modal";
import Page from "../../components/Page";
import {
  Accordion,
  AccordionPanel,
  Button,
  ClipboardButton,
  CodeArea,
  CodeText,
  Container, Divider,
  Tabs as TabsComponent,
  Fieldset, Form, FormError, FormField, InfoText, Select, Table, TableHeader, TableRow, TextInput
} from "../../components/StyledComponents";
import useAdminClient from "../../hooks/useAdminClient";
import useClipboard from "../../hooks/useClipboard";
import useLoader from "../../hooks/useLoader";
import useNotification from "../../hooks/useNotification";
import "../../prism-themes/prism-one-light.css";
import { NotificationType } from "../../providers/NotificationProvider";
import ResourceAPI from "../../ResourceAPI";
import ExtendedClientRepresentation, { ClientType } from "../../types/ExtendedClientRepresentation";
import { Administrator } from "../../types/RoleTypes";
import authorisedFunction from "../../utils/AuthorisedFunction";
import { setupAuthorisation } from "../../utils/utils";
import { Tab, Tabs } from "../../components/Tabs";
import "./index.css";
import Copy from "../../icons/Copy";
import IconButton from "../../components/IconButton";
import Delete from "../../icons/Delete";
import SkeletonItem, { SkeletonContainer } from "../../components/SkeletonItem";
import { MAX_LIST_ITEMS_PER_PAGE } from "../../constants";

import useElementOnScreen from "../../hooks/useElementOnScreen";

const DefaultClient: ExtendedClientRepresentation = {
  attributes: {
    clientType: "5gmediahub",
    login_theme: "5gmediahub",
  },
  authorizationServicesEnabled: true,
  authorizationSettings: {
    allowRemoteResourceManagement: true,
    policies: [{
      name: "Only Owner Policy",
      type: "script-only-owner.js"
    }],
    policyEnforcementMode: "ENFORCING",
    scopes: [
      {
        name: "create"
      }, {
        name: "view"
      }, {
        name: "view-private"
      }, {
        name: "manage"
      }, {
        name: "delete"
      }]
  },
  bearerOnly: false,
  clientAuthenticatorType: "client-secret",
  clientId: "",
  clientType: ClientType.Confidential,
  consentRequired: false,
  directAccessGrantsEnabled: false,
  enabled: true,
  fullScopeAllowed: true,
  pkceEnabled: false,
  protocol: "openid-connect",
  publicClient: false,
  redirectUris: [""],
  rootUrl: "",
  serviceAccountsEnabled: true,
  standardFlowEnabled: true,
  webOrigins: [""]
};

const DefaultResource: ResourceBody = {
  baseUri: "",
  groups: [],
  isPrivate: false,
  name: "",
  owner: "",
  type: ""
};

interface ITabProps {
  id: string;
}

interface IDownloadProps {
  data: Object;
}

const InstallationTabSkeleton = () => (
  <SkeletonContainer width="60%">
    <Box flex align="center">
      <SkeletonItem width="250px" height="40px" />
    </Box>
    <SkeletonItem height="180px" />
    <SkeletonItem height="40px" />
  </SkeletonContainer>
);

const SettingsTabSkeleton = () => (
  <SkeletonContainer width="60%">
    <SkeletonItem height="40px" />
    <SkeletonItem height="40px" />
    <SkeletonItem height="40px" />
    <Divider />
    <SkeletonItem height="40px" />
    <Divider />
    <SkeletonItem height="40px" />
    <SkeletonItem height="40px" />
    <SkeletonItem height="40px" />
    <SkeletonItem height="75px" />
    <div style={{
      display: "flex",
      justifyContent: "space-between",
    }}
    >
      <SkeletonItem width="120px" height="36px" />
      <SkeletonItem width="120px" height="36px" />
    </div>
  </SkeletonContainer>
);

const EditClientPageSkeleton = () => (
  <div style={{
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%"
  }}
  >
    <SkeletonItem width="480px" height="40px" />
    <SettingsTabSkeleton />
  </div>
);

const DownloadLink = (props: IDownloadProps) => {
  const { data } = props;
  const [downloadLink, setDownloadLink] = useState("");

  const createJsonFile = () => {
    const d = new Blob([JSON.stringify(data, null, 2)], { type: "application/json" });
    if (downloadLink !== "") window.URL.revokeObjectURL(downloadLink);

    setDownloadLink(window.URL.createObjectURL(d));
  };

  useEffect(() => {
    createJsonFile();
  }, [data]);
  return (
    <Button
      primary
      onClick={() => {
        const link = document.createElement("a");
        link.href = downloadLink;
        link.download = "config.json";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }}
      label="Download"
    />
  );
};

const AuthResourcesTab = (props: ITabProps) => {
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();
  const adminClient = useAdminClient();
  const { addNotification } = useNotification();
  const { id } = props;
  const [showResModal, setShowResModal] = useState(false);
  const [newResource, setNewResource] = useState<ResourceBody>(DefaultResource);
  const [secret, setSecret] = useState<CredentialRepresentation>({});
  const [client, setClient] = useState<ClientRepresentation>(DefaultClient);
  const [createPermission, setCreatePermission] = useState(false);
  const [resToDelete, setResToDelete] = useState<ResourceRepresentation | undefined>(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [availableGroups, setAvailableGroups] = useState<GroupRepresentation[]>([]);

  const fetchResources = async (p: number) => {
    if (!p) return [];
    try {
      const res = await adminClient.clients.listResources({
        deep: false,
        first: MAX_LIST_ITEMS_PER_PAGE * (p - 1),
        id,
        max: MAX_LIST_ITEMS_PER_PAGE,
      });
      return res;
    } catch (err) {
      const e = err as AxiosError;
      if (e.response) {
        addNotification(`Load Application Resources: ${e.response.statusText}`);
      } else {
        addNotification("Unable to load Application Resources");
      }
    }
    return [];
  };

  const {
    isLoading,
    isError,
    error,
    data,
    fetchNextPage,
    isFetching,
    hasNextPage
  } = useInfiniteQuery(["client-resources"], ({ pageParam = 1 }) => fetchResources(pageParam), {
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length > 0) {
        return allPages.length + 1;
      }
      return false;
    }
  });

  const [containerRef] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 1.0
  }, (visible) => {
    if (visible && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  });

  const [copyID, setCopyID] = useState("");

  const [copy] = useClipboard(copyID);

  useEffect(() => {
    if (copyID.length > 0) {
      copy();
      addNotification("Copied to clipboard successfully!", NotificationType.Success);
    }
  }, [copyID]);

  const onChange = (value: any) => {
    setNewResource(value);
  };

  useEffect(() => {
    if (showResModal && secret.value && client.clientId && client.clientId.length > 0) {
      ResourceAPI.get("/groups/my", { headers: { "X-Client": Buffer.from(`${client.clientId}:${secret.value}`).toString("base64") } })
        .then((response) => setAvailableGroups(response.data));
    }
  }, [showResModal]);

  useEffect(() => {
    if (secret.value && client.clientId && client.clientId.length > 0) {
      ResourceAPI.get("/permissions/create", { headers: { "X-Client": Buffer.from(`${client.clientId}:${secret.value}`).toString("base64") } })
        .then(() => setCreatePermission(true))
        .catch(() => setCreatePermission(false));
    }
  }, [secret, client]);

  useEffect(() => {
    adminClient.clients.findOne({
      id
    })
      .then((c) => {
        setClient(c as any);
      })
      .catch((err) => {
        const e = err as AxiosError;
        if (e.response) {
          addNotification(`Load Client: ${e.response.statusText}`);
        } else {
          addNotification("Unable to load Client");
        }
      });
  }, []);

  useEffect(() => {
    adminClient.clients.getClientSecret({ id })
      .then((credentials) => {
        setSecret(credentials);
      })
      .catch((err) => {
        const e = err as AxiosError;
        if (e.response) {
          addNotification(`Load Client Secret: ${e.response.statusText}`);
        } else {
          addNotification("Unable to load Client Secret");
        }
      });
  }, []);

  const resetForm = () => {
    setNewResource(DefaultResource);
  };

  const deleteResource = async (resId: string) => {
    try {
      await adminClient.clients.delResource({ id, resourceId: resId });
      queryClient.resetQueries("client-resources");
    } catch (err) {
      const e = err as AxiosError;
      if (e.response) {
        addNotification(`Delete Resource: ${e.response.statusText}`);
      } else {
        addNotification("Unable to delete resource");
      }
    }
  };

  const createResource = async () => {
    try {
      const resource = newResource;
      // Tidy up groups
      if (newResource.groups && newResource.groups.length > 0) {
        resource.groups = [];
        for (let i = 0; i < newResource.groups.length; i += 1) {
          resource.groups.push({
            id: newResource.groups[i].id,
            name: newResource.groups[i].name
          });
        }
      }
      resource.owner = keycloak.tokenParsed?.sub;
      const response = await ResourceAPI.post("/resources", resource, { headers: { "X-Client": Buffer.from(`${client.clientId}:${secret.value}`).toString("base64") } });
      addNotification(`Created resource${response.data.id}`, NotificationType.Success);
      queryClient.resetQueries("client-resources");
    } catch (err) {
      const e = err as AxiosError;
      if (e.response) {
        addNotification(`Create Resource: ${e.response.statusText}`);
      } else {
        addNotification("Unable to create resource");
      }
    }
  };

  return (
    <Container>
      <Table width="100%">
        <TableHeader>
          <TableRow>
            <TableCell scope="col" border="bottom" colSpan={3}>
              <strong>Name</strong>
            </TableCell>
            <TableCell scope="col" border="bottom" colSpan={3}>
              <strong>Type</strong>
            </TableCell>
            <TableCell scope="col" border="bottom" colSpan={3}>
              <strong>Owner</strong>
            </TableCell>
            <TableCell scope="col" border="bottom" colSpan={1}>
              <strong>Action</strong>
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {
            (!isLoading && data)
            && (data.pages.map((page) => page.map((resource) => (
              <TableRow key={`${resource}-row`}>
                <TableCell scope="row" colSpan={3}>{resource.name}</TableCell>
                <TableCell scope="row" colSpan={3}>{resource.type}</TableCell>
                <TableCell scope="row" colSpan={3}>{(resource as any).owner.name}</TableCell>
                <TableCell scope="row" direction="row" gap="small" colSpan={1}>
                  <IconButton icon={<Copy />} onClick={() => { setCopyID(`${resource._id}`); }} />
                  {
                    resource.name !== "base"
                    && (
                      <IconButton
                        icon={<Delete />}
                        onClick={() => {
                          setResToDelete(resource);
                          setShowDeleteModal(true);
                        }}
                      />
                    )
                  }

                </TableCell>
              </TableRow>
            ))))
          }
          <div className="overflow-box" ref={containerRef}>
            {isFetching && <Spinner />}
          </div>
        </TableBody>
      </Table>
      {createPermission
        && <Button primary label="Create resource" onClick={() => setShowResModal(true)} />}
      <Modal
        confirm
        show={showDeleteModal}
        confirmFn={() => deleteResource(`${resToDelete?._id}`)}
        setShow={setShowDeleteModal}
        children={`Are you sure you want to delete the resource ${resToDelete?.name}? This will only delete it on the User Management Module, but cannot be undone.`}
      />
      <Modal
        confirm
        large
        confirmLabel="Submit"
        rejectLabel="Cancel"
        show={showResModal}
        setShow={setShowResModal}
        onClose={resetForm}
        children={(
          <Form value={newResource} onChange={onChange} className="width-100">
            <Fieldset>
              <Heading level="3">Create resource</Heading>
              <FormField name="name" label="Name" required>
                <TextInput name="name" />
                <InfoText>
                  The resource name must be unique, and in a format similar to
                  {" "}
                  <CodeText>my-net-app</CodeText>
                  . It must begin and end with an alphabetical character and can contain 3-32 alphanumeric
                  characters or non-repeating hyphens and underscores.
                </InfoText>
              </FormField>
              <FormField name="type" label="Resource Type" required>
                <TextInput name="type" />
                <InfoText>
                  The resource type will be used to group resource items together.
                  For example, all NetApp resources could use the type
                  {" "}
                  <CodeText>net-app</CodeText>
                  .
                </InfoText>
              </FormField>
              <FormField name="baseUri" label="Base URI" required>
                <TextInput name="baseUri" />
              </FormField>
              <FormField name="groups" label="Group">
                <Select
                  name="groups"
                  id="group-select"
                  clear
                  valueKey="id"
                  labelKey="name"
                  options={availableGroups}
                  multiple
                />
                <InfoText>
                  Select groups that the resource should be shared with. Multiple groups can be selected.
                </InfoText>
              </FormField>
              <FormField name="isPrivate" required label="Resource Privacy">
                <CheckBox name="isPrivate" label="Private" />
                <InfoText>
                  Specify whether the resource should be private.
                  Private resources can only be seen by those it has been shared with.
                </InfoText>
              </FormField>
            </Fieldset>
          </Form>
        )}
        confirmFn={() => createResource()}
      />
    </Container>
  );
};

const InstallationTab = (props: ITabProps) => {
  const adminClient = useAdminClient();
  const { addNotification } = useNotification();
  const { id } = props;

  const [provider, setProvider] = useState<Object>({});
  const textAreaRef = useRef(null);
  const codeElement = useRef<HTMLElement | null>(null);

  const [copy] = useClipboard(JSON.stringify(provider, null, 2));
  const [copyText, setCopyText] = useState("Copy");
  const [ready, setReady] = useState(false);

  const onCopyClick = () => {
    copy();
    setCopyText("Copied!");
    setTimeout(() => {
      setCopyText("Copy");
    }, 3000);
  };

  useEffect(() => {
    if (codeElement.current) {
      Prism.highlightElement(codeElement.current);
    }
  }, [provider]);

  useEffect(() => {
    adminClient.clients.getInstallationProviders({ id, providerId: "keycloak-oidc-keycloak-json" })
      .then((value) => {
        const val = value as any;
        val["resource-management-api"] = "https://ummbackend.netapps-5gmediahub.eu/api";
        const prov = {
          "auth-server-url": `${val["auth-server-url"]}realms/${val.realm}`,
          credentials: {
            "client-id": val.resource,
            secret: val.credentials && val.credentials.secret ? val.credentials.secret : undefined,
            "x-client": val.credentials && val.credentials.secret ? Buffer.from(`${val.resource}:${val.credentials.secret}`).toString("base64") : undefined
          },
          realm: val.realm,
          "resource-management-api": "https://ummbackend.netapps-5gmediahub.eu/api"
        };
        setProvider(prov);
      })
      .catch((err) => {
        const error = err as AxiosError;
        if (error.response) {
          addNotification(`Load provider: ${error.response.statusText}`);
        } else {
          addNotification("Unable to load provider");
        }
      })
      .finally(() => setReady(true));
  }, []);

  return (
    <>
      {!ready && <InstallationTabSkeleton />}

      {ready && (
        <Container>
          <Box fill gap="medium">
            <a
              className="openid-link"
              href={`${process.env.REACT_APP_AUTH_SERVER_ADDRESS}/realms/${process.env.REACT_APP_AUTH_REALM}/.well-known/openid-configuration`}
              target="_blank"
              rel="noreferrer"
            >
              View OpenID Connect configuration
            </a>
            <CodeArea ref={textAreaRef}>
              <code ref={codeElement} className="language-json">{JSON.stringify(provider, null, 2)}</code>
              <ClipboardButton
                disabled={copyText !== "Copy"}
                primary
                label={copyText}
                size="small"
                onClick={() => onCopyClick()}
              />
            </CodeArea>
            <DownloadLink data={provider} />
          </Box>
        </Container>
      )}
    </>
  );
};

const SettingsTab = (props: ITabProps) => {
  const adminClient = useAdminClient();
  const { addNotification } = useNotification();
  const { id } = props;
  const [client, setClient] = useState<ExtendedClientRepresentation>(DefaultClient);
  const [prevAuthEnabled, setPrevAuthEnabled] = useState(false);
  const [loader, showLoader, hideLoader] = useLoader();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [valid, setValid] = useState(false);
  const [ready, setReady] = useState(false);

  const handleArrayObjectChange = (event: any, index: number, array: string, key?: string) => {
    const arr = ((client as any)[`${array}`] as Array<any>).slice();
    if (key) {
      arr[index][key] = event.target.value;
    } else {
      arr[index] = event.target.value;
    }

    setClient((prevClient) => ({
      ...prevClient,
      [array]: arr
    }));
  };

  const addElementToArray = (obj: Object, array: string, fn: SetStateAction<any>, item?: any) => {
    let arr = (obj as any)[array];
    if (arr) {
      if (item !== undefined) {
        arr.push(item);
      } else {
        arr.push({});
      }
    } else if (item !== undefined) {
      arr = [item];
    } else {
      arr = [{}];
    }

    fn((prev: any) => ({
      ...prev,
      [array]: arr
    }));
  };

  const removeElementFromArray = (obj: Object, array: string, index: number, fn: SetStateAction<any>) => {
    const arr = (obj as any)[array];
    if (arr) {
      arr.splice(index, 1);

      fn((prev: any) => ({
        ...prev,
        [array]: arr
      }));
    }
  };

  const onChange = (value: any) => {
    setClient(value);
  };

  useEffect(() => {
    adminClient.clients.findOne({
      id
    })
      .then((c) => {
        if ((c?.webOrigins === undefined) || (c.webOrigins.length === 0)) {
          if (!c) {
            c = {};
          }
          c.webOrigins = [""];
        }
        if ((c?.redirectUris === undefined) || (c.redirectUris.length === 0)) {
          if (!c) {
            c = {};
          }
          c.redirectUris = [""];
        }
        const extended: ExtendedClientRepresentation = c;
        if (extended.attributes && extended.attributes["pkce.code.challenge.method"] === "S256") {
          extended.pkceEnabled = true;
        } else {
          extended.pkceEnabled = false;
        }
        if (extended.publicClient) {
          extended.clientType = ClientType.Public;
        } else if (extended.bearerOnly) {
          extended.clientType = ClientType.BearerOnly;
        } else {
          extended.clientType = ClientType.Confidential;
        }
        if (c.authorizationServicesEnabled) {
          setPrevAuthEnabled(true);
        }
        setClient(extended);
      })
      .catch((err) => {
        const error = err as AxiosError;
        if (error.response) {
          addNotification(`Load Client: ${error.response.statusText}`);
        } else {
          addNotification("Unable to load Client");
        }
      })
      .finally(() => setReady(true));
  }, []);

  useEffect(() => {
    if (client.webOrigins) {
      if (client.webOrigins.length === 0) {
        const c = client;
        c.webOrigins = [""];
        setClient(c);
      }
    } else {
      const c = client;
      c.webOrigins = [""];
      setClient(c);
    }
  }, [client]);

  useEffect(() => {
    const c = client;
    switch (client.clientType) {
      case ClientType.BearerOnly:
        c.bearerOnly = true;
        c.publicClient = false;
        c.authorizationServicesEnabled = false;
        c.serviceAccountsEnabled = false;
        c.pkceEnabled = false;
        c.webOrigins = [""];
        break;
      case ClientType.Public:
        c.bearerOnly = false;
        c.publicClient = true;
        c.authorizationServicesEnabled = false;
        c.serviceAccountsEnabled = false;
        break;
      case ClientType.Confidential:
        c.bearerOnly = false;
        c.publicClient = false;
        c.authorizationServicesEnabled = true;
        c.serviceAccountsEnabled = true;
        c.pkceEnabled = false;
        break;
      default:
        break;
    }
    setClient(c);
  }, [client.clientType]);

  const submit = async () => {
    try {
      showLoader();
      if (!client.attributes) {
        client.attributes = {
          clientType: "5gmediahub",
        };
      } else if (!client.attributes.clientType) {
        client.attributes.clientType = "5gmediahub";
      }
      if (client.attributes) {
        client.attributes["pkce.code.challenge.method"] = client.pkceEnabled ? "S256" : "";
      }
      if (client.attributes) {
        client.attributes["pkce.code.challenge.method"] = client.pkceEnabled ? "S256" : undefined;
      }
      const c: any = { ...client };
      // Remove any black Redirect URIs or Web Origins
      const redirectUris = client.redirectUris?.filter((item) => item.trim().length > 0);
      const webOrigins = client.webOrigins?.filter((item) => item.trim().length > 0);
      c.redirectUris = redirectUris;
      c.webOrigins = webOrigins;

      c.clientType = undefined;
      c.resourceTypes = undefined;
      c.pkceEnabled = undefined;
      if (!client.authorizationServicesEnabled) {
        c.authorizationSettings = undefined;
      }
      // Delete any keys with undefined values to prevent issues
      Object.keys(c).forEach((key) => c[key] === undefined && delete c[key]);
      await adminClient.clients.update({ id: `${client.id}` }, c);

      hideLoader();
      addNotification("Application updated successfully", NotificationType.Success);
    } catch (err) {
      hideLoader();
      const error = err as AxiosError;
      if (error.response) {
        addNotification(`Update application: ${error.response.statusText}`);
      } else {
        addNotification("Unable to update application.");
      }
    }

    try {
      if (client.authorizationServicesEnabled && !prevAuthEnabled) {
        showLoader();
        await setupAuthorisation(`${client.id}`, adminClient);
      }
    } catch (err) {
      hideLoader();
      const error = err as AxiosError;
      if (error.response) {
        if (error.response.status !== 409) {
          addNotification(`Update application: ${error.response.statusText}`);
        }
      } else {
        addNotification("Unable to update application.");
      }
    }
  };

  useEffect(() => {
    const c = client;
    switch (c.clientType) {
      case ClientType.BearerOnly:
        c.bearerOnly = true;
        c.publicClient = false;
        c.authorizationServicesEnabled = false;
        c.serviceAccountsEnabled = false;
        c.pkceEnabled = false;
        break;
      case ClientType.Public:
        c.bearerOnly = false;
        c.publicClient = true;
        c.authorizationServicesEnabled = false;
        c.serviceAccountsEnabled = false;
        break;
      case ClientType.Confidential:
        c.bearerOnly = false;
        c.publicClient = false;
        break;
      default:
        break;
    }
    setClient(c);
  }, [client.clientType]);

  const deleteClient = async () => {
    try {
      showLoader();
      await adminClient.clients.del({ id });
      navigate("/apps");
    } catch (err) {
      const error = err as AxiosError;
      if (error.response) {
        addNotification(`Delete Application : ${error.response.statusText}`);
      } else {
        addNotification("Unable to delete Application");
      }
    }
    hideLoader();
  };

  return (
    <>
      {!ready && <SettingsTabSkeleton />}
      {ready && (
        <Form
          value={client}
          onChange={onChange}
          onSubmit={submit}
          validate="change"
          onValidate={(results) => setValid(results.valid)}
        >
          <Fieldset disabled={!authorisedFunction([Administrator])}>
            <Heading level="4">Basic information</Heading>
            <FormField
              name="clientId"
              label="Client ID"
              required
              validate={(val) => {
                if (val.trim().length === 0 || !(/^(?!-)[a-zA-Z-_]{3,32}(?<!-)$/gm.test(val))) {
                  return (
                    <FormError>
                      Invalid Client ID. Must be in a format similar to
                      {" "}
                      <CodeText>my-client-application</CodeText>
                    </FormError>
                  );
                }
                return undefined;
              }}
            >
              <TextInput name="clientId" />
              <InfoText>
                The Client ID must be unique, and in a format similar to
                {" "}
                <CodeText>my-client-application</CodeText>
                . It must begin with an alphabetical character and can contain 3-32 alphanumeric
                characters or non-repeating hyphens.
              </InfoText>
            </FormField>
            <FormField name="name" label="Display name">
              <TextInput name="name" placeholder="Add a reader-friendly name" />
            </FormField>
            <FormField name="description" label="Description">
              <TextInput name="description" placeholder="Add a description in less than 140 characters" />
            </FormField>
          </Fieldset>
          <Divider />
          <Fieldset disabled={!authorisedFunction([Administrator])}>
            <Heading level="4">Application Properties</Heading>
            <FormField
              name="clientType"
              label="Application Type"
            >
              <Select
                name="clientType"
                options={[ClientType.Public, ClientType.Confidential, ClientType.BearerOnly]}
                defaultValue={client.clientType}
              />
              <InfoText>
                The type of application will determine which settings you can configure from the dashboard.
              </InfoText>
            </FormField>
          </Fieldset>
          <Divider />
          <Fieldset disabled={!authorisedFunction([Administrator])}>
            <Heading level="4">Application URIs</Heading>
            <FormField name="rootUrl" label="Root URL">
              <TextInput name="rootUrl" disabled={client.clientType === ClientType.BearerOnly} />
            </FormField>
            <FormField
              name="redirectUris"
              label="Allowed Callback URLs"
              required
              validate={(val) => {
                if (client.clientType === ClientType.BearerOnly) {
                  return undefined;
                }
                let validItems = false;
                for (let i = 0; i < val.length; i += 1) {
                  if (val[i].trim().length > 0) {
                    validItems = true;
                  }
                  if (val[i].trim().length === 0 && i === val.length - 1 && !validItems) {
                    return <FormError>At least one valid callback URL must be specified</FormError>;
                  }
                }
                return undefined;
              }}
            >
              <Box gap="small">
                {
                  client.redirectUris?.map((uri, index) => (
                    <Box direction="row" gap="small">
                      <TextInput
                        key={`redirectUri[${index}]`}
                        value={uri}
                        onChange={(event) => handleArrayObjectChange(event, index, "redirectUris")}
                        disabled={client.clientType === ClientType.BearerOnly}
                      />
                      {
                        (index === (client.redirectUris!.length - 1))
                        && (
                          <Button
                            secondary
                            label="+"
                            onClick={() => addElementToArray(client, "redirectUris", setClient, "")}
                            disabled={client.clientType === ClientType.BearerOnly}
                          />
                        )
                      }
                      {
                        (index !== (client.redirectUris!.length - 1))
                        && (
                          <Button
                            secondary
                            label="-"
                            onClick={() => removeElementFromArray(client, "redirectUris", index, setClient)}
                            disabled={client.clientType === ClientType.BearerOnly}
                          />
                        )
                      }
                    </Box>
                  ))
                }
              </Box>
              <InfoText>
                After the user authenticates, callbacks will only be allowed to any of these URLs.
                Make sure to specify the protocol (e.g.
                {" "}
                <CodeText>https://</CodeText>
                ) or the callback may fail. If you have specified a
                {" "}
                <CodeText>Root URL</CodeText>
                {" "}
                then you may use relative URLs, e.g.
                {" "}
                <CodeText>/login/callback</CodeText>
              </InfoText>
            </FormField>
            <FormField name="webOrigins" label="Web Origins">
              <Box gap="small">
                {
                  client.webOrigins?.map((uri, index) => (
                    <Box direction="row" gap="small">
                      <TextInput
                        key={`webOrigin[${index}]`}
                        value={uri}
                        onChange={(event) => handleArrayObjectChange(event, index, "webOrigins")}
                        disabled={client.clientType === ClientType.BearerOnly}
                      />
                      {
                        (index === (client.webOrigins!.length - 1))
                        && (
                          <Button
                            secondary
                            label="+"
                            onClick={() => addElementToArray(client, "webOrigins", setClient)}
                            disabled={client.clientType === ClientType.BearerOnly}
                          />
                        )
                      }
                      {
                        (index !== (client.webOrigins!.length - 1))
                        && (
                          <Button
                            secondary
                            label="-"
                            onClick={() => removeElementFromArray(client, "webOrigins", index, setClient)}
                            disabled={client.clientType === ClientType.BearerOnly}
                          />
                        )
                      }
                    </Box>
                  ))
                }
              </Box>
              <InfoText>
                Allowed CORS origins. To permit all origins of Valid Callback URLs, add
                {" "}
                <CodeText>+</CodeText>
                . To permit all origins explicitly, use the
                {" "}
                <CodeText>*</CodeText>
                {" "}
                wildcard.
              </InfoText>
            </FormField>
          </Fieldset>
          <Fieldset disabled={!authorisedFunction([Administrator])}>
            <Accordion pad="none">
              <AccordionPanel label="Advanced Settings">
                <Box pad={{ vertical: "medium" }}>
                  <TabsComponent>
                    <TabComponent title="Grant Types">
                      <Box
                        pad={{ vertical: "medium" }}
                        flex
                        wrap
                        justify="start"
                        align="start"
                        direction="row"
                        gap="medium"
                      >
                        <FormField name="directAccessGrantsEnabled">
                          <CheckBox
                            disabled={client.clientType === ClientType.BearerOnly}
                            label="Password"
                            name="directAccessGrantsEnabled"
                          />
                        </FormField>
                      </Box>
                    </TabComponent>
                    <TabComponent title="OAuth">
                      <Box
                        pad={{ vertical: "medium" }}
                        flex
                        wrap
                        justify="start"
                        align="start"
                        direction="row"
                        gap="medium"
                      >
                        <FormField name="authorizationServicesEnabled">
                          <CheckBox
                            disabled={client.clientType !== ClientType.Confidential}
                            name="authorizationServicesEnabled"
                            label="Resource Authorisation"
                          />
                        </FormField>
                        <FormField name="serviceAccountsEnabled">
                          <CheckBox
                            disabled={client.clientType !== ClientType.Confidential}
                            name="serviceAccountsEnabled"
                            label="Service Accounts"
                          />
                        </FormField>
                        <FormField name="consentRequired">
                          <CheckBox
                            name="consentRequired"
                            label="Consent Required"
                          />
                        </FormField>
                      </Box>
                    </TabComponent>
                    <TabComponent title="Authentication">
                      <Box
                        pad={{ vertical: "medium" }}
                        flex
                        wrap
                        justify="start"
                        align="start"
                        direction="row"
                        gap="medium"
                      >
                        <FormField name="pkceEnabled">
                          <CheckBox
                            name="pkceEnabled"
                            disabled={client.clientType === ClientType.BearerOnly}
                            label="Proof Key for Code Exchange (PKCE)"
                          />
                        </FormField>
                      </Box>
                    </TabComponent>
                  </TabsComponent>
                </Box>
              </AccordionPanel>
            </Accordion>
          </Fieldset>
          {authorisedFunction([Administrator])
            && (
              <Box direction="row" justify="between" margin={{ top: "medium" }}>
                <Button label="Delete client" onClick={() => setShowDeleteModal(true)} type="reset" />
                <Button type="submit" primary label="Save changes" />
              </Box>
            )}
          <Modal
            confirm
            show={showDeleteModal}
            setShow={setShowDeleteModal}
            children="Are you sure you want to delete this client? All your apps using this client will stop working."
            confirmFn={() => deleteClient()}
          />
          {loader}
        </Form>
      )}
    </>
  );
};

const CredentialsTab = (props: ITabProps) => {
  const adminClient = useAdminClient();
  const { addNotification } = useNotification();
  const { id } = props;
  const [secret, setSecret] = useState<CredentialRepresentation>({});
  const [client, setClient] = useState<ClientRepresentation>(DefaultClient);
  useEffect(() => {
    adminClient.clients.findOne({
      id
    })
      .then((c) => {
        setClient(c as any);
      })
      .catch((err) => {
        const error = err as AxiosError;
        if (error.response) {
          addNotification(`Load Client: ${error.response.statusText}`);
        } else {
          addNotification("Unable to load Client");
        }
      });
  }, []);

  const [copyID, setCopyID] = useState("");

  const [copy] = useClipboard(copyID);

  useEffect(() => {
    if (copyID.length > 0) {
      copy();
      addNotification("Copied to clipboard successfully!", NotificationType.Success);
    }
  }, [copyID]);

  useEffect(() => {
    adminClient.clients.getClientSecret({ id })
      .then((credentials) => {
        setSecret(credentials);
      })
      .catch((err) => {
        const error = err as AxiosError;
        if (error.response) {
          addNotification(`Load Client Secret: ${error.response.statusText}`);
        } else {
          addNotification("Unable to load Client Secret");
        }
      });
  }, []);

  return (
    <Container>
      <Box fill gap="small">
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col" border="bottom">
                Parameter
              </TableCell>
              <TableCell scope="col" border="bottom">
                Value
              </TableCell>
              <TableCell scope="col" border="bottom">
                Actions
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell scope="row">
                Client ID
              </TableCell>
              <TableCell><code>{client.clientId}</code></TableCell>
              <TableCell>
                <Button
                  className="action-btn"
                  label="Copy to clipboard"
                  onClick={() => { setCopyID(`${client.clientId}`); }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">
                Secret
              </TableCell>
              <TableCell><code>{secret.value}</code></TableCell>
              <TableCell>
                <Button
                  className="action-btn"
                  label="Copy to clipboard"
                  onClick={() => { setCopyID(`${secret.value}`); }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">
                Base64
              </TableCell>
              <TableCell><code>{Buffer.from(`${client.clientId}:${secret.value}`).toString("base64")}</code></TableCell>
              <TableCell>
                <Button
                  className="action-btn"
                  label="Copy to clipboard"
                  onClick={() => { setCopyID(Buffer.from(`${client.clientId}:${secret.value}`).toString("base64")); }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Container>
  );
};

const ClientPage = () => {
  const adminClient = useAdminClient();
  const { id } = useParams<any>();
  const { addNotification } = useNotification();
  const navigate = useNavigate();
  const [client, setClient] = useState<ClientRepresentation>(DefaultClient);
  const [ready, setReady] = useState(false);

  if (id === undefined) {
    navigate("/apps");
    return (<></>);
  }

  useEffect(() => {
    if (id) {
      adminClient.clients.findOne({
        id
      })
        .then((c) => {
          setClient(c as any);
        })
        .catch((err) => {
          const error = err as AxiosError;
          if (error.response) {
            addNotification(`Load Application: ${error.response.statusText}`);
          } else {
            addNotification("Unable to load Application");
          }
        })
        .finally(() => setReady(true));
    }
  }, []);

  return (
    <Page
      locationHeader={[
        {
          name: "Applications",
          path: "/apps"
        },
        {
          name: "Edit"
        }
      ]}
      title="Edit Application"
    >
      {
        !ready && (
          <EditClientPageSkeleton />
        )
      }
      {
        ready && (
          <Box width="100%" flex align="center">
            <Tabs>
              <Tab title="Settings">
                <SettingsTab id={id} />
              </Tab>
              <Tab title="Credentials" disabled={client.publicClient}>
                <CredentialsTab id={id} />
              </Tab>
              {
                authorisedFunction([Administrator])
                && (
                  <Tab title="Resources" disabled={client.publicClient}>
                    <AuthResourcesTab id={id} />
                  </Tab>
                )
              }
              <Tab title="Installation">
                <InstallationTab id={id} />
              </Tab>
            </Tabs>
          </Box>
        )
      }
    </Page>
  );
};

export default ClientPage;
