import GroupRepresentation from "@keycloak/keycloak-admin-client/lib/defs/groupRepresentation";

export interface ExtendedGroupRepresentation extends GroupRepresentation {
  parent?: GroupRepresentation;
  subgroup?: boolean;
}

export const ExtendedGroupRepresentationSchema = {
  $id: "5gmediahub/group.json#",
  properties: {
    id: {
      type: "string"
    },
    name: {
      type: "string"
    },
    path: {
      type: "string"
    }
  },
  required: ["id", "name"],
  type: "object"
};
