import styled from "styled-components";
import {
  Accordion as GrommetAccordion,
  AccordionPanel as GrommetAccordionPanel,
  Box as GrommetBox,
  Button as GrommetButton,
  Card as GrommetCard,
  FormField as GrommetFormField,
  Heading as GrommetHeading,
  MaskedInput as GrommetMaskedInput,
  TextInput as GrommetTextInput,
  Form as GrommetForm,
  Select as GrommetSelect,
  TableHeader as GrommetTableHeader,
  TableRow as GrommetTableRow,
  Table as GrommetTable,
  Tabs as GrommetTabs,
  TextArea as GrommetTextArea,
} from "grommet";
import { NavLink as NvLink } from "react-router-dom";

export const TextInput = styled(GrommetTextInput)`
  background-color: var(--input-colour);
  border: none;
  font-weight: normal;
  font-size: 14px;
`;

export const TextArea = styled(GrommetTextArea)`
  background-color: var(--input-colour);
  border: none;
  font-weight: normal;
  font-size: 14px;
`;

export const MaskedInput = styled(GrommetMaskedInput)`
  background-color: var(--input-colour);
  border: none;
  font-weight: normal;
  font-size: 14px;
`;

export const FormField = styled(GrommetFormField)`
  & > div {
    border: none;
    padding: 0;

    & > button {
      border: none;
    }
  }

  & > span {
    color: var(--error-text-colour);
    margin: 0;
    font-size: 0.875rem;
    margin-top: 8px;
    text-align: left;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0.01071em;
    user-select: none;
    /* font-size: 10px;
    margin: 0; */
  }
`;

const HeaderNavItem = styled(NvLink)`
  text-decoration: none;
  font-weight: 600;
`;

export const HeaderNavLink = (props: any) => <HeaderNavItem {...props} />;

const NavItem = styled(NvLink)`
  text-decoration: none;
  /* color: var(--link-colour); */
  color: white;
  cursor: pointer;
  position: relative;
  padding: 1.5rem 0;
  transition: background 0.3s ease;
  margin: 0 1em;
  border-radius: 18px;

  svg {
    width: 1.2rem;
    height: auto;
    fill: var(--link-colour);
    stroke: var(--link-colour);
  }

  &.active {
    /* color: var(--link-colour); */
    color: white;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 18px;
    
  }

  & .active::after {
    /* border-right: 4px solid var(--link-colour); */
  }

  &::after {
    /* content: "";
    top: 0;
    right: 0;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease; */
  }

  &:not(.active)::after {
    border: none;
    border-right: 4px solid var(--link-colour);
    transform: scaleY(0);
  }

  &:not(.active):hover {
    color: var(--link-colour);
    color: white;
    background: rgba(255, 255, 255, 0.4);
  }

  &:not(.active):hover::after {
    transform: scaleY(1);
  }
`;

export const NavLink = (props: any) => <NavItem {...props} />;

export const Card = styled(GrommetCard)`
  height: 100%;
  max-height: 250px;
  transition: all 0.2s ease-in-out;

  & > header > * {
    text-align: center;
    width: 100%;
  }

  &:hover {
    transform: scale(95%);
    cursor: pointer;
  }

  &:hover > header > .del-btn {
    opacity: 1;
  }

  flex-basis: 50%;
`;

export const Button = styled(GrommetButton)`
  border-radius: 4px;
`;

export const Form = styled(GrommetForm)`
  background-color: white;
  border-radius: 4px;
  box-sizing: border-box;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 24px;
  height: max-content;
  display: grid;
  grid-gap: 40px;
  text-align: start;

  @media (max-width: 768px) {
    width: 100%;
  }

  width: 60%;
`;

export const Select = styled(GrommetSelect)`
  font-size: 14px;
`;

interface ILoaderProps {
  show?: boolean;
}

export const Loader = styled.div<ILoaderProps>`
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(247, 248, 250, 0.75);
  position: fixed;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? "all" : "none")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  background-color: white;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 24px;
  height: max-content;

  @media (max-width: 768px) {
    width: 100%;
  }

  width: 60%;
`;

export const Tabs = styled(GrommetTabs)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 24px;
  width: 100%;
  align-items: center;

  &:first-child {
    width: 100%;
    user-select: none;
  }

  [role=tabpanel] {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const Table = styled(GrommetTable)`
  width: 100%;
`;

export const CodeTextArea = styled(GrommetTextArea)`
  background-color: var(--input-colour);
  border: none;
  font-weight: normal;
  font-size: 14px;
  font-family: monospace;
  user-select: none;
`;

export const CodeArea = styled.pre`
  background-color: var(--input-colour);
  border: none;
  font-weight: normal;
  font-size: 14px;
  font-family: monospace;
  user-select: none;
  padding: 12px;
  position: relative;
  border-radius: 4px;

  & > button {
    opacity: 0;
  }

  &:hover {
    & > button {
      opacity: 1;
    }
  }
`;

export const ClipboardButton = styled(Button)`
  position: absolute;
  top: 12px;
  right: 12px;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  background: transparent;
  color: var(--brand-colour);
  border: none;
  opacity: 1;
  transition: all 0.3s;
  padding: 0;

  &:hover {
    color: #354052;
  }

  &:hover:not(:disabled) {
    box-shadow: none;
    opacity: 1;
  }

  & > svg {
    fill: #354052;
    stroke: #354052;
  }
`;

export const DisplayCard = styled(GrommetBox)`
  border-radius: 6px;
  transition: border-bottom 0.3s;
  overflow: hidden;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: var(--brand-colour);
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
    transform: translate3d(-100%, 0, 0);
  }

  &:hover::after {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

export const CardHeading = styled(GrommetHeading)`
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: start;
  color: var(--sub-header-colour);
`;

export const CardSubHeading = styled.div`
  color: var(--link-colour);
  text-transform: uppercase;
  letter-spacing: normal;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  width: 85px;
`;

export const CardSubValueDisplay = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
`;

export const FixedButton = styled(Button)`
  position: fixed;
  width: 256px;
  bottom: 24px;
  right: 24px;
`;

export const RoleListContainer = styled.div`
  width: 100%;
  background: var(--input-colour);
  padding: 12px;
  border-radius: 4px;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

interface IRoleListProps {
  highlight?: boolean;
}

export const RoleList = styled.ul<IRoleListProps>`
  list-style-type: none;
  min-height: 100px;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  background: ${(props) => (props.highlight ? "var(--brand-colour)" : "none")};
  transition: all 0.3s;

  & > li:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const RoleListItem = styled.li`
  padding: 6px;
  background: white;
`;

interface IButtonProps {
  primary?: boolean;
  secondary?: boolean;
}

export const NewButton = styled.button<IButtonProps>`
  text-align: center;
  pointer-events: auto;
  transform-origin: 50% 50%;
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  padding: 12px 24px;
  cursor: pointer;
  user-select: none;
  transition: 0.25s;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 1.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  color: ${(props) => (props.primary === true ? "#f5f5f5" : "var(--text-colour)")};
  font-family: 'Inter', sans-serif;
  outline: none;
  border: none;
  background: ${(props) => (props.primary === true ? "rgba(214, 50, 132, 0.8)" : "#f5f5f5")};
  border-radius: 4px;

  &:active {
    transition: 0.15s;
    opacity: 0.8;
  }

  &::after {
    border-radius: 4px;
    position: absolute;
    content: "";
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: ${(props) => (props.primary === true ? "1px solid #f2c1da" : "2px solid var(--brand-colour)")};
    transition: 0.25s;
  }

  &:hover {
    box-shadow: 0 20px 25px -15px rgba(0, 0, 0, 0.25);
    background: ${(props) => (props.primary === true ? "var(--brand-colour)" : "#f5f5f5")};

    &::after {
      border-color: var(--brand-colour);
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
    }
  }
`;

export const TableHeader = styled(GrommetTableHeader)`
  font-weight: 300;
  text-transform: uppercase;
  border-bottom: 2px solid var(--link-colour);
  & > tr {
    background: none !important;
    user-select: none;
  }
`;

export const TableRow = styled(GrommetTableRow)`
  line-height: 1.5;
  padding: 7px 40px 7px 0;
  &:hover {
    background-color: #f8f8f8;
    transition: 0.1s ease;
  }
`;

export const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: var(--link-colour);
`;

export const InfoText = styled.span`
  color: var(--info-text-colour);
  margin: 0;
  font-size: 0.875rem;
  margin-top: 8px;
  text-align: left;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.01071em;
  user-select: none;
`;

export const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
`;

export const CodeText = styled.code`
  display: inline;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: #eff0f2;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  letter-spacing: 0.02083em;
`;

export const Accordion = styled(GrommetAccordion)`
  border: 1px solid var(--link-colour);
  padding: 24px;
  border-radius: 4px;
`;

export const AccordionPanel = styled(GrommetAccordionPanel)`
  & > :first-child {
    padding: 0 !important;
    user-select: none;
  }
`;

export const Link = styled(NavLink)`
  transition: all 0.2s ease-in-out;
  &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none;
      color: inherit;
  }
  &:hover {
    color: black;
    font-weight: bolder;
  }
`;

export const FormError = styled.span`
  color: var(--error-text-colour);
  margin: 0;
  font-size: 0.875rem;
  text-align: left;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.01071em;
  user-select: none;
`;
