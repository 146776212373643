import GroupRepresentation from "@keycloak/keycloak-admin-client/lib/defs/groupRepresentation";
import { useLocation } from "react-router-dom";
import Card from "..";

interface GroupCardProps {
  group: GroupRepresentation;
}

const GroupCard = (props: GroupCardProps) => {
  const { group } = props;

  const { pathname } = useLocation();

  const location = {
    pathname: `/groups/${group.id}`
  };

  if (pathname.startsWith("/groups/")) {
    location.pathname = `/subgroups/${group.id}`;
  }

  return (
    <Card location={location} title={`${group.name}`} sub={[]} />
  );
};

export default GroupCard;
