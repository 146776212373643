/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unused-prop-types */
import {
  createRef, Dispatch, ReactNode, SetStateAction, useEffect, useLayoutEffect, useState
} from "react";
import "./index.css";

interface CommonProps {
  confirm?: boolean;
  children: string | ReactNode;
  large?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

type ConfirmModalProps =
  | { confirm?: false; confirmFn?: never; rejectFn?: never; confirmLabel?: never; rejectLabel?: never; }
  | { confirm?: true; confirmFn: () => void; rejectFn?: () => void; confirmLabel?: string; rejectLabel?: string; }

type ModalProps = CommonProps & ConfirmModalProps;

const Modal = (props: ModalProps) => {
  const {
    children, show, setShow, confirm, confirmFn, rejectFn, confirmLabel, rejectLabel, onClose, onOpen, large,
  } = props;

  const ref = createRef<HTMLDivElement>();
  const [autoHeight, setAutoHeight] = useState(true);

  useEffect(() => {
    if (show && onOpen) {
      onOpen();
    }
    if (!show && onClose) {
      onClose();
    }
  }, [show]);

  const fn1 = () => {
    if (confirmFn) {
      confirmFn();
    }
    setShow(false);
  };

  const fn2 = () => {
    if (rejectFn) {
      rejectFn();
    }
    setShow(false);
  };

  useLayoutEffect(() => {
    if (ref.current) {
      if (ref.current?.clientHeight < (ref.current.scrollHeight - 1)) {
        setAutoHeight(false);
      } else {
        setAutoHeight(true);
      }
    } else {
      setAutoHeight(true);
    }
  }, [ref]);

  return (
    <div
      className={`modal-popup ${show === true ? "is-visible" : ""}`}
      role="alert"
      onClick={(e: any) => { e.preventDefault(); setShow(false); }}
    >
      <div className="modal-outer">
        <div className={`modal-popup-container ${autoHeight ? "height-auto" : "height-100"} ${large === true ? "large" : ""}`} onClick={(event) => event.stopPropagation()} ref={ref}>
          <div>{children}</div>
          <ul className={`modal-buttons ${!confirm ? "single" : ""}`}>
            {
              confirm === true ? (
                <>
                  <li><button onClick={() => fn1()} type="button">{confirmLabel || "Yes"}</button></li>
                  <li><button onClick={() => fn2()} type="button">{rejectLabel || "No"}</button></li>
                </>
              ) : (<li><button onClick={() => fn1()} type="button">OK</button></li>)
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Modal;
