/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Box, Spinner } from "grommet";

import { useState } from "react";
import "./index.css";
import { AxiosError } from "axios";
import { Add, Search } from "grommet-icons";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useInfiniteQuery } from "react-query";
import Page from "../../../components/Page";
import UserCard from "../../../components/Cards/UserCard";
import useAdminClient from "../../../hooks/useAdminClient";
import useNotification from "../../../hooks/useNotification";
import { Button, TextInput } from "../../../components/StyledComponents";
import { Administrator } from "../../../types/RoleTypes";
import authorisedFunction from "../../../utils/AuthorisedFunction";
import { CardSkeleton } from "../../../components/Cards";
import useElementOnScreen from "../../../hooks/useElementOnScreen";
import { MAX_LIST_ITEMS_PER_PAGE } from "../../../constants";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05
    }
  }
};

const UsersPage = () => {
  const navigate = useNavigate();
  const adminClient = useAdminClient();
  const { addNotification } = useNotification();
  const [filter, setFilter] = useState("");

  const fetchUsers = async (p: number) => {
    if (!p) {
      return {
        info: {
          page: 1,
          total: 0,
        },
        users: []
      };
    };
    try {
      const count = await adminClient.users.count();
      const res = await adminClient.users.find({
        first: MAX_LIST_ITEMS_PER_PAGE * (p - 1),
        max: MAX_LIST_ITEMS_PER_PAGE,
      });
      return {
        info: {
          page: p,
          total: count,
        },
        users: res
      };
    } catch (err) {
      const e = err as AxiosError;
      if (e.response) {
        addNotification(`Load Users: ${e.response.statusText}`);
      } else {
        addNotification("Unable to load Users");
      }
    }
    return {
      info: {
        page: 1,
        total: 0
      },
      users: []
    };
  };

  const {
    isLoading,
    isError,
    error,
    data,
    fetchNextPage,
    isFetching,
    hasNextPage
  } = useInfiniteQuery(["users"], ({ pageParam = 1 }) => fetchUsers(pageParam), {
    getNextPageParam: (lastPage) => {
      if (lastPage.info.page * MAX_LIST_ITEMS_PER_PAGE < lastPage.info.total) {
        return lastPage.info.page + 1;
      }
      return false;
    }
  });

  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 1.0
  }, (visible) => {
    if (visible && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  });

  const createUser = () => {
    navigate("/users/create");
  };

  return (
    <Page
      locationHeader={[
        {
          name: "Users",
          path: "/users"
        },
        {
          name: "List"
        }
      ]}
      title="Users"
      pageControls={(
        <>
          <div className="input-content">
            <TextInput
              icon={(
                <Search />
              )}
              height="fit-content"
              placeholder="Search..."
              className="search-input"
              value={filter}
              onChange={(_event) => setFilter(_event.target.value)}
            />
          </div>
          {authorisedFunction([Administrator]) && (
            <Button
              primary
              label={(
                <span className="button-label">
                  <span className="label-icon"><Add /></span>
                  {" "}
                  Create user
                </span>
              )}
              className="header-btn-add-new"
              onClick={createUser}
            />
          )}
        </>
      )}
    >
      <Box
        fill
        margin={{
          vertical: "small",
        }}
      />
      <div>
        {
          isLoading
          && (
            <motion.div className="card-grid" variants={container} initial="hidden" animate="show">
              {[...Array(5 * 5)].map(() => <CardSkeleton />)}
            </motion.div>
          )
        }
        {
          (!isLoading && data) && (
            <motion.div
              className="card-grid"
              variants={container}
              initial="hidden"
              animate="show"
            >
              {
                data.pages.map((us) => us.users.filter((_user) => _user.username?.includes(filter)).map((user) => (
                  <UserCard user={user} key={user.id} />
                )))
              }
            </motion.div>
          )
        }
        <div className="overflow-box" ref={containerRef}>
          {isFetching && <Spinner />}
        </div>
      </div>
    </Page>
  );
};

export default UsersPage;
