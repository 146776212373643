/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { motion } from "framer-motion";
import {
  ReactNode, useState
} from "react";
import "./index.css";

interface ITabProps {
  children: ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  disabled?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  title: string;
}

const Tab = (props: ITabProps) => {
  const { children } = props;

  return (
    <div className="tab-container">{children}</div>
  );
};

const variants = {
  center: {
    opacity: 1,
    x: 0,
    zIndex: 1
  },
  enter: (direction: number) => ({
    opacity: 0,
    transition: {
      delay: 3
    },
    x: direction > 0 ? 3000 : -3000
  }),
  exit: (direction: number) => ({
    opacity: 0,
    x: direction < 0 ? 3000 : -3000,
    zIndex: 0
  })
};

interface ITabsProps {
  children: ReactNode;// ReactElement<ITabProps> | ReactElement<ITabProps>[];
}

const Tabs = (props: ITabsProps) => {
  const [[page, direction], setPage] = useState([0, 0]);
  const { children } = props;

  let tabs = Array.isArray(children) ? children : [children];
  tabs = tabs.filter((item) => item !== false);

  return (
    <div className="tabs-container">
      <ul className="tabs-header">
        {
          tabs.map((tab, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={`tab-${index}`}
              className={`${page === index ? "tab-active-header" : ""} ${tab.props.disabled ? "tab-disabled-header" : ""}`}
              onClick={() => setPage([index, index - page])}
            >
              <h4>{tab.props.title}</h4>
              {
                page === index && (
                  <motion.div className="underline" layoutId={`underline-${tabs[0].props.title}`} />
                )
              }
            </li>
          ))
        }
        <div className="underline-bg" />
      </ul>
      <motion.section
        key={page}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          opacity: {
            duration: 1
          },
          x: {
            damping: 30,
            duration: 1,
            stiffness: 300,
            type: "spring"
          }
        }}
      >
        <Tab {...tabs[page].props} />
      </motion.section>
    </div>
  );
};

export { Tab, Tabs };

export default Tabs;
