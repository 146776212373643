import Ajv, { Schema } from "ajv";
import addFormats from "ajv-formats";
import ajvErrors from "ajv-errors";

const validator = new Ajv({
  allErrors: true
});

addFormats(validator);
ajvErrors(validator);

const parseErrors = () => {
  const errorObject: any = {};
  for (let i = 0; i < validator.errors!.length; i += 1) {
    const len = validator.errors![i].instancePath.length;
    errorObject[`${validator.errors![i].instancePath.slice(1, len)}`] = validator.errors![i].message;
  }
  return errorObject;
};

const validate = (data: unknown, schema: string | Schema) => {
  const valid = validator.validate(schema, data);

  if (valid !== undefined && validator.errors) {
    return parseErrors();
  }

  return {};
};

export default validate;
