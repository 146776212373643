import { motion } from "framer-motion";
import { cloneElement, MouseEventHandler, ReactElement } from "react";
import "./index.css";

interface IconButtonProps {
  className?: string;
  colour?: string;
  icon: ReactElement;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}
const IconButton = (props: IconButtonProps) => {
  const {
    className, colour, icon, onClick
  } = props;
  return (
    <motion.button
      className={`mediahub-icon-button ${className}`}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      onClick={onClick}
    >
      {cloneElement(icon, { colour: colour ?? "var(--brand-colour)" })}
    </motion.button>
  );
};

export default IconButton;
