import { useContext } from "react";
import { NotificationContext } from "../../providers/NotificationProvider";

export default function useNotification() {
  const { notifications, addNotification, removeNotification } = useContext(NotificationContext);
  return {
    addNotification,
    notifications,
    removeNotification,
  };
}
