/* eslint-disable no-unreachable */
import UserRepresentation from "@keycloak/keycloak-admin-client/lib/defs/userRepresentation";
import Card from "..";
import "../index.css";

interface UserCardProps {
  user: UserRepresentation;
}

const UserCard = (props: UserCardProps) => {
  const { user } = props;
  const displayName = `${user.firstName ? user.firstName : ""} ${user.lastName ? user.lastName : ""}`.trim();

  const location = {
    pathname: `/users/${user.id}`
  };
  const sub = [
    {
      title: "Name",
      value: displayName
    }
  ];

  return (
    <>
      <Card location={location} title={`${user.email}`} sub={sub} />
    </>
  );
};

export default UserCard;
