const theme: any = {
  accordion: {
    border: {
      color: "transparent"
    }
  },
  formField: {
    error: {
      color: "var(--error-text-colour)",
      margin: {
        bottom: "none",
        horizontal: "none",
        top: "8px"
      },
      size: "0.875rem",
    },
    label: {
      color: "#7F8FA4",
      margin: {
        horizontal: "none",
        vertical: "small"
      },
      requiredIndicator: true,
      size: "12px"
    }
  },
  global: {
    colors: {
      "accent-1": "#bbc5d5",
      brand: "#D63284",
      focus: "rgba(214, 50, 132, 0.25)",
      text: {
        dark: "white",
        light: "#354052",
      }
    },
    font: {
      family: "Inter",
    },
    input: {
      font: {
        weight: "normal"
      }
    }
  },
  heading: {
    level: {
      1: {
        font: {
          size: "8px"
        }
      },
      4: {
        font: {
          size: "24px",
          weight: "bold"
        }
      },
      5: {
        font: {
          family: "Inter",
          weight: "normal"
        },
        small: {
          size: "14px"
        }
      },
      6: {
        font: {
          family: "Inter",
          weight: "300"
        }
      }
    }
  },
  pagination: {
    icons: {
      color: "brand"
    }
  },
  select: {
    background: "#f7f8fa",
    control: {
      extend: `
        border: none;
    `,
    },
    options: {
      container: {
        border: false,
        pad: "small"
      },
      text: {
        size: "14px"
      },
    },
  },
  tab: {
    active: {
      color: "brand"
    },
    border: {
      active: {
        color: "brand"
      },
      color: "transparent",
      hover: {
        color: "accent-1"
      },
    },
    color: "text",
    hover: {
      color: "accent-1"
    }
  },
  tip: {
    drop: {
      align: {
        top: "top"
      }
    }
  }
};

export default theme;
