import ClientRepresentation from "@keycloak/keycloak-admin-client/lib/defs/clientRepresentation";
import Card from "..";

interface IClientCardProps {
  client: ClientRepresentation;
}

const ClientCard = (props: IClientCardProps) => {
  const { client } = props;

  const location = {
    pathname: `/apps/${client.id}`
  };

  let clientType;
  if (client.publicClient) {
    clientType = "Public";
  } else if (client.bearerOnly) {
    clientType = "Bearer Only";
  } else {
    clientType = "Confidential";
  }

  const sub = [
    {
      title: "Root URL",
      value: client.rootUrl
    },
    {
      title: "Type",
      value: clientType
    }
  ];

  return (
    <>
      <Card location={location} title={`${client.clientId}`} sub={sub} />
    </>
  );
};

export default ClientCard;
