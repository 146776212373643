/* eslint-disable no-extra-semi */
/* eslint-disable semi */
import ClientRepresentation from "@keycloak/keycloak-admin-client/lib/defs/clientRepresentation";

// eslint-disable-next-line no-shadow
export enum ClientType {
  Public = "public",
  Confidential = "confidential",
  BearerOnly = "bearer-only",
}

export default interface ExtendedClientRepresentation extends ClientRepresentation {
  clientType?: ClientType;
  pkceEnabled?: boolean;
};
