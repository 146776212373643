import { useCallback } from "react";

const useClipboard = (text: string): [() => void] => {
  const copyToClipboard = (str: string) => {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readyonly", "");
    el.style.position = "fixed";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    const selected = document.getSelection()!.rangeCount > 0
      ? document.getSelection()!.getRangeAt(0)
      : false;
    el.select();
    const success = document.execCommand("copy");
    document.body.removeChild(el);
    if (selected) {
      document.getSelection()!.removeAllRanges();
      document.getSelection()?.addRange(selected);
    }
    return success;
  };

  const copy = useCallback(() => {
    copyToClipboard(text);
  }, [text]);

  return [copy];
};

export default useClipboard;
