import { Box, Paragraph, Spinner } from "grommet";
import { Loader } from "../StyledComponents";

interface ILoaderProps {
  show?: boolean;
}

const FullPageLoader = (props: ILoaderProps) => {
  const { show } = props;
  return (
    <Loader show={show}>
      <Box fill align="center" justify="center">
        <Spinner message="Loading" color="brand" size="medium" />
        <Paragraph>Just a moment...</Paragraph>
      </Box>
    </Loader>
  );
};

export default FullPageLoader;
