import { Heading } from "grommet";
import { Fragment } from "react";
import "./PageHeader.css";
import { HeaderNavLink } from "./StyledComponents";

export interface LocationHeader {
  name: string;
  path?: string;
}

interface PageHeaderProps {
  location?: Array<LocationHeader>;
  title: string;
}

const PageHeader = (props: PageHeaderProps) => {
  const { location, title } = props;
  return (
    <>
      {
        location && (
          <div className="page-header-container">
            {
              location.map((item, index) => {
                if (item.path) {
                  return (
                    <>
                      <HeaderNavLink to={item.path}>{item.name}</HeaderNavLink>
                      {
                        (index < location.length - 1)
                        && <span className="separator">{" // "}</span>
                      }
                    </>
                  );
                }
                return (
                  <>
                    <span className="page-title">{item.name}</span>
                    {
                      (index < location.length - 1)
                      && <span className="separator">{" // "}</span>
                    }
                  </>
                );
              })
            }
          </div>
        )
      }
      {
        location === undefined && (
          <>
            <Heading level="1" size="small" margin="none">
              Dashboard
            </Heading>
            <Heading level="5" size="small" margin="none">
              User Management Portal
            </Heading>
            <Heading level="6" size="small" margin="none">
              {title}
              {" "}
              Page
            </Heading>

          </>
        )
      }

    </>
  );
};

export default PageHeader;
