import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

interface AuthState {
  token: string | null;
}

const initialState: AuthState = {
  token: null,
};

const authSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    setToken(state, action) {
      return {
        ...state,
        token: action.payload
      };
    }
  }
});

export const { setToken } = authSlice.actions;

export const selectToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;
