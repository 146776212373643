import { useKeycloak } from "@react-keycloak/web";
import { Box } from "grommet";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import AppBar from "../components/AppBar";
import PrivateRoute from "../components/PrivateRoute";
import ClientsPage from "../pages/ClientsPage";
import CreateClientPage from "../pages/CreateClientPage";
import CreateUserPage from "../pages/Users/Create";
import EditUserPage from "../pages/Users/Edit";
import HomePage from "../pages/HomePage";
import UnauthorisedPage from "../pages/UnauthorisedPage";
import UsersPage from "../pages/Users/List";
import ClientPage from "../pages/ClientPage";
import GroupsPage from "../pages/Groups/List";
import CreateGroupPage from "../pages/Groups/Create";
import GroupEditPage from "../pages/Groups/Edit";
import "./index.css";
import { Administrator, Developer } from "../types/RoleTypes";
import LoadingPage from "../pages/LoadingPage";
import LoginPage from "../pages/LoginPage";

const AppRouter = () => {
  const { initialized, keycloak } = useKeycloak();

  useEffect(() => {
    keycloak.updateToken(30)
      .catch(() => console.error("Failed to refresh token"));
    setInterval(() => {
      keycloak.updateToken(30)
        .catch(() => console.error("Failed to refresh token"));
    }, 30000);
  }, []);

  return (
    <Box fill>
      <Box
        direction="row"
        flex
        style={{ position: "relative" }}
        overflow={{ horizontal: "hidden" }}
        background="var(--input-colour)"
      >
        {
          initialized ? (
            <Router>
              <AppBar />
              <Box
                overflow="auto"
                className="content-area"
              >
                <AnimatePresence>
                  <Routes>
                    <Route element={<PrivateRoute />}>
                      <Route path="/" element={<HomePage />} />
                    </Route>
                    <Route element={<PrivateRoute />}>
                      <Route path="/users" element={<UsersPage />} />
                    </Route>
                    <Route element={<PrivateRoute />}>
                      <Route path="/users/:id" element={<EditUserPage />} />
                    </Route>
                    <Route element={<PrivateRoute roles={[Administrator]} />}>
                      <Route path="/users/create" element={<CreateUserPage />} />
                    </Route>
                    <Route element={<PrivateRoute roles={[Administrator, Developer]} />}>
                      <Route path="apps" element={<ClientsPage />} />
                    </Route>
                    <Route element={<PrivateRoute roles={[Administrator, Developer]} />}>
                      <Route path="apps/create" element={<CreateClientPage />} />
                    </Route>
                    <Route element={<PrivateRoute roles={[Administrator, Developer]} />}>
                      <Route path="apps/:id" element={<ClientPage />} />
                    </Route>
                    <Route element={<PrivateRoute roles={[Administrator, Developer]} />}>
                      <Route path="/groups" element={<GroupsPage />} />
                    </Route>
                    <Route element={<PrivateRoute roles={[Administrator, Developer]} />}>
                      <Route path="groups/create" element={<CreateGroupPage />} />
                    </Route>
                    <Route element={<PrivateRoute roles={[Administrator, Developer]} />}>
                      <Route path="groups/:id" element={<GroupEditPage />} />
                    </Route>
                    <Route path="unauthorised" element={<UnauthorisedPage />} />
                    <Route path="login" element={<LoginPage />} />
                  </Routes>
                </AnimatePresence>
              </Box>
            </Router>
          ) : <LoadingPage />
        }
      </Box>
    </Box>
  );
};

export default AppRouter;
