import { Grid } from "grommet";
import { ReactNode } from "react";
import "./index.css";

import { motion } from "framer-motion";
import PageHeader, { LocationHeader } from "../PageHeader";

interface PageProps {
  children?: ReactNode;
  locationHeader?: Array<LocationHeader>;
  pageControls?: ReactNode;
  title: string;
}

const pageVariants = {
  in: {
    opacity: 1
  },
  initial: {
    opacity: 0
  },
  out: {
    opacity: 0
  }
};

const Page = (props: PageProps) => {
  const {
    children, locationHeader, pageControls, title
  } = props;
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
    >
      <Grid
        fill
        columns={["flex", "flex"]}
        gap="medium"
        justifyContent="start"
      >
        <div>
          <PageHeader title={title} location={locationHeader} />
        </div>
        <div>
          <div className="search-header-container">
            {pageControls}
          </div>
        </div>
      </Grid>
      {children}
    </motion.div>
  );
};

export default Page;
