/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { AxiosError } from "axios";
import { motion } from "framer-motion";
import { Box, Spinner } from "grommet";
import { Add, Search } from "grommet-icons";
import { useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { CardSkeleton } from "../../components/Cards";
import ClientCard from "../../components/Cards/ClientCard";

import Page from "../../components/Page";
import { Button, TextInput } from "../../components/StyledComponents";
import { MAX_LIST_ITEMS_PER_PAGE } from "../../constants";
import useAdminClient from "../../hooks/useAdminClient";
import useElementOnScreen from "../../hooks/useElementOnScreen";
import useNotification from "../../hooks/useNotification";
import { Administrator } from "../../types/RoleTypes";
import authorisedFunction from "../../utils/AuthorisedFunction";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05
    }
  }
};

const ClientsPage = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotification();
  const adminClient = useAdminClient();
  const [filter, setFilter] = useState("");

  const fetchClients = async (p: number) => {
    if (!p) return [];
    try {
      const res = await adminClient.clients.find({
        first: MAX_LIST_ITEMS_PER_PAGE * (p - 1),
        max: MAX_LIST_ITEMS_PER_PAGE,
      });
      return res.filter((cl) => cl.attributes && cl.attributes.clientType === "5gmediahub");
    } catch (err) {
      const e = err as AxiosError;
      if (e.response) {
        addNotification(`Load Applications: ${e.response.statusText}`);
      } else {
        addNotification("Unable to load Applications");
      }
    }
    return [];
  };

  const {
    isLoading,
    isError,
    error,
    data,
    fetchNextPage,
    isFetching,
    hasNextPage
  } = useInfiniteQuery(["clients"], ({ pageParam = 1 }) => fetchClients(pageParam), {
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length > 0) {
        return allPages.length + 1;
      }
      return false;
    }
  });

  const [containerRef] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 1.0
  }, (visible) => {
    if (visible && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  });

  const createClient = () => {
    navigate("/apps/create");
  };

  return (
    <Page
      title="Applications"
      locationHeader={[
        {
          name: "Applications",
          path: "/apps"
        },
        {
          name: "List"
        }
      ]}
      pageControls={(
        <>
          <div className="input-content">
            <TextInput
              icon={(
                <Search />
              )}
              height="fit-content"
              placeholder="Search..."
              className="search-input"
              value={filter}
              onChange={(_event) => setFilter(_event.target.value)}
            />
          </div>
          {
            authorisedFunction([Administrator])
            && (
              <Button
                primary
                label={(
                  <span className="button-label">
                    <span className="label-icon"><Add /></span>
                    {" "}
                    Create application
                  </span>
                )}
                className="header-btn-add-new"
                onClick={createClient}
              />
            )
          }
        </>
      )}
    >
      <Box
        margin={{
          vertical: "small",
        }}
      />
      <div>
        {
          isLoading
          && (
            <motion.div className="card-grid" variants={container} initial="hidden" animate="show">
              {[...Array(5 * 5)].map(() => <CardSkeleton />)}
            </motion.div>
          )
        }
        {
          (!isLoading && data) && (
            <motion.div className="card-grid" variants={container} initial="hidden" animate="show">
              {
                data.pages.map((page) => page.filter((_client) => _client.clientId?.includes(filter)).map((client) => (
                  <ClientCard client={client} />
                )))
              }
            </motion.div>
          )
        }
        <div className="overflow-box" ref={containerRef}>
          {isFetching && <Spinner />}
        </div>
      </div>
    </Page>
  );
};

export default ClientsPage;
