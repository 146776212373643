import { motion } from "framer-motion";
import { Box } from "grommet";
import { LocationDescriptor } from "history";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  CardHeading, CardSubHeading, CardSubValueDisplay,
} from "../StyledComponents";
import "./index.css";

interface IValueDisplay {
  title: string;
  value?: string;
}

export interface ICardProps {
  location: LocationDescriptor<unknown>;
  title: string;
  sub: Array<IValueDisplay>;
}

const variants = {
  hidden: { opacity: 0, scale: 0.8 },
  show: { opacity: 1, scale: 1 }
};

const Card = (props: ICardProps) => {
  const navigate = useNavigate();
  const {
    location, title, sub
  } = props;

  function handleClick() {
    navigate(location);
  }

  const buildComponentDisplay = (obj: IValueDisplay) => {
    const val = `${obj.value || ""}`.trim();
    return (
      <Box flex direction="column">
        <Box flex direction="row" gap="medium">
          <CardSubHeading>{obj.title}</CardSubHeading>
          {
            (val.length > 0)
              ? <CardSubValueDisplay>{val}</CardSubValueDisplay>
              : <CardSubValueDisplay style={{ opacity: 0.5 }}>Nothing provided</CardSubValueDisplay>
          }
        </Box>
      </Box>
    );
  };

  return (
    <motion.button
      whileHover={{ boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.1)", scale: 1.1, zIndex: 10 }}
      whileTap={{ scale: 0.9 }}
      onClick={handleClick}
      className="display-card"
      animate={{ opacity: 1, scale: 1 }}
      variants={variants}
    >
      <Box
        flex
        gap="medium"
        direction="row"
        pad="medium"
      >
        <div className="card-indicator" />
        <Box flex direction="column" gap="small" margin={{ right: "medium" }}>
          <CardHeading level="4">{title}</CardHeading>
          <Box flex direction="column" gap="xsmall" className="card-content-container">
            {
              sub.map((item) => buildComponentDisplay(item))
            }
          </Box>
        </Box>
      </Box>
    </motion.button>
  );
};

const CardSkeletonHeading = styled.div`
  margin: 0;
  background: var(--link-colour);
  border-radius: 6px;
  height: 24px;
`;

const CardSkeletonSubHeading = styled.div`
  font-size: 16px;
  white-space: nowrap;
  width: 85px;
  background: var(--link-colour);
  border-radius: 6px;
  height: 16px;
`;

const CardSubValue = styled.div`
  background: var(--link-colour);
  border-radius: 6px;
  width: 100%;
  height: 16px;
`;

export const CardSkeleton = () => (
  <motion.div
    variants={variants}
    className="display-card"
  >
    <Box
      flex
      gap="medium"
      direction="row"
      pad="medium"
    >
      <div className="card-indicator" />
      <Box flex direction="column" gap="small">
        <CardSkeletonHeading />
        <Box flex direction="column">
          <Box flex direction="row" gap="medium">
            <CardSkeletonSubHeading />
            <CardSubValue />
          </Box>
        </Box>
      </Box>
    </Box>
  </motion.div>
);

export default Card;
