import axios, { AxiosRequestConfig } from "axios";
import keycloak from "./keycloak";
import { memoizedRefreshToken } from "./utils/utils";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_AUTH_SERVER_ADDRESS}/admin/realms/5GMediaHUB`
});

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem("5gmediahub.umm.access_token");
  config.headers.Authorization = `Bearer ${token}`;
  console.log(config);
  return config;
});

instance.interceptors.response.use((response) => response, async (error: any) => {
  const { config } = error;
  if (error.response && error.response.status === 401 && !config?.sent) {
    config.sent = true;

    const result = await memoizedRefreshToken();

    if (result.access_token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${result.access_token}`
      };
      keycloak.token = result.access_token;
      keycloak.refreshToken = `${result.refresh_token}`;
    }

    return instance(config);
  }
  return Promise.reject(error);
});

export default instance;
