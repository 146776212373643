import { useKeycloak } from "@react-keycloak/web";
import {
  Navigate,
  Outlet,
  RouteProps,
  useLocation,
} from "react-router-dom";

interface PrivateRouteParams extends RouteProps {
  roles?: string[];
}

const PrivateRoute = (props: PrivateRouteParams) => {
  const { roles } = props;
  const { keycloak } = useKeycloak();
  const location = useLocation();

  if (roles) {
    return (
      roles.some((role) => keycloak.hasRealmRole(role) || keycloak.hasResourceRole(role, "umm-portal"))
        ? <Outlet />
        : keycloak.authenticated
          ? <Navigate to="/unauthorised" state={{ from: location }} replace />
          : <Navigate to="/login" state={{ from: location }} replace />
    );
  }
  return (
    keycloak.authenticated
      ? <Outlet />
      : <Navigate to="/login" state={{ from: location }} replace />
  );
};
export default PrivateRoute;
