/* eslint-disable max-len */
interface IProps {
  className?: string;
  colour?: string;
  height?: number;
}

const MediaHubLogo = (props: IProps) => {
  const defaultWidth = 50;
  const defaultHeight = 50;
  const { className, colour, height } = props;
  const col = colour ?? "#fff";
  const h: string | number = height ?? "1em";
  const w = (typeof h === "number") ? (h / defaultHeight) * defaultWidth : "1em";

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={w}
      height={h}
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 512 512"
      className={className}
    >
      <g>
        <path
          fill={col}
          d={`M386,261.6c-0.7-2.5-1.6-5.1-2.5-7.5c-0.7-2-1.5-4-2.3-6c0-0.1-0.1-0.2-0.1-0.3c-0.8-1.8-1.6-3.7-2.5-5.4
c-0.8-1.6-1.6-3.3-2.5-4.8c-0.8-1.5-1.6-2.9-2.5-4.4c-0.8-1.4-1.6-2.7-2.5-4c-0.8-1.3-1.6-2.5-2.5-3.8c-0.8-1.2-1.6-2.4-2.5-3.5
c-0.8-1.1-1.6-2.1-2.5-3.2c-0.8-1-1.6-2-2.5-3c-0.8-1-1.6-1.9-2.5-2.9c-0.8-0.9-1.6-1.8-2.5-2.6c-0.6-0.7-1.3-1.4-1.9-2
c-0.2-0.2-0.4-0.4-0.6-0.5c-0.8-0.8-1.6-1.6-2.5-2.4c-0.8-0.8-1.6-1.5-2.5-2.2c-0.8-0.7-1.6-1.4-2.5-2.1c-0.8-0.7-1.6-1.3-2.5-2
c-0.8-0.7-1.6-1.3-2.5-1.9c-0.8-0.6-1.6-1.2-2.5-1.8c-0.8-0.6-1.6-1.2-2.5-1.7c-0.8-0.6-1.6-1.1-2.5-1.6c-0.8-0.5-1.6-1.1-2.5-1.6
c-0.8-0.5-1.6-1-2.5-1.5c-0.8-0.5-1.6-1-2.5-1.4c-0.8-0.5-1.6-0.9-2.5-1.3c-0.8-0.4-1.6-0.9-2.5-1.3c-0.8-0.4-1.6-0.8-2.5-1.2
c-0.8-0.4-1.6-0.8-2.5-1.1c-0.7-0.3-1.4-0.7-2.1-1c-0.1,0-0.2-0.1-0.3-0.1c-0.8-0.3-1.6-0.7-2.5-1c-0.8-0.3-1.6-0.6-2.5-1
c-0.8-0.3-1.6-0.6-2.5-0.9c-0.8-0.3-1.6-0.6-2.5-0.8c-0.8-0.3-1.6-0.5-2.5-0.8c-0.8-0.2-1.6-0.5-2.5-0.7c-0.8-0.2-1.6-0.5-2.5-0.7
c-0.8-0.2-1.6-0.4-2.5-0.6c-0.8-0.2-1.6-0.4-2.5-0.5c-0.8-0.2-1.6-0.3-2.5-0.5c-0.8-0.2-1.6-0.3-2.5-0.4c-0.8-0.1-1.6-0.3-2.5-0.4
c-0.8-0.1-1.6-0.2-2.5-0.3c-0.8-0.1-1.6-0.2-2.5-0.3c-0.8-0.1-1.6-0.2-2.5-0.2c-0.8-0.1-1.6-0.1-2.5-0.2c-0.8-0.1-1.6-0.1-2.5-0.1
c-0.8,0-1.6-0.1-2.5-0.1c-0.8,0-1.6,0-2.5,0c-0.5,0-1.1,0-1.6,0c-0.3,0-0.6,0-0.9,0c-0.8,0-1.6,0-2.5,0c-0.8,0-1.6,0.1-2.5,0.1
c-0.8,0-1.6,0.1-2.5,0.1c-0.8,0.1-1.6,0.1-2.5,0.2c-0.8,0.1-1.6,0.1-2.5,0.2c-0.8,0.1-1.6,0.2-2.5,0.3c-0.8,0.1-1.6,0.2-2.5,0.3
c-0.8,0.1-1.6,0.2-2.5,0.4c-0.8,0.1-1.6,0.3-2.5,0.4c-0.8,0.2-1.6,0.3-2.5,0.5c-0.8,0.2-1.6,0.4-2.5,0.5c-0.8,0.2-1.6,0.4-2.5,0.6
c-0.8,0.2-1.6,0.4-2.5,0.6c-0.8,0.2-1.6,0.5-2.5,0.7c-0.8,0.2-1.6,0.5-2.5,0.7c-0.8,0.3-1.6,0.5-2.5,0.8c-0.8,0.3-1.6,0.6-2.5,0.9
c-0.8,0.3-1.6,0.6-2.5,0.9c-0.8,0.3-1.6,0.6-2.5,1c-0.8,0.3-1.6,0.7-2.5,1.1c-0.8,0.4-1.6,0.7-2.5,1.1c-0.8,0.4-1.6,0.8-2.5,1.2
c-0.8,0.4-1.6,0.8-2.5,1.2c-0.2,0.1-0.5,0.2-0.7,0.4l0.7-9.9l2.5-34.3l2.2-30.8h0.2h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5
h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5
h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h0.8V52.9h-0.8h-2.5H354h-2.5H349h-2.5h-2.5h-2.5h-2.5h-2.5
h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5H317h-2.5H312h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5H275
h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5H238h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5
h-2.5h-2.5h-2.5h-2.5H201h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5h-2.5H164h-2.5h-2.4l-0.1,1.4
l-2.5,34.5l-2.5,34.5l-2.5,34.5l-2.5,34.5l-2.5,34.5l-2.5,34.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5
h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2c0.2-0.2,0.3-0.5,0.5-0.7c0.8-1.1,1.6-2.3,2.5-3.4c0.8-1,1.6-2,2.5-3
c0.8-0.9,1.6-1.8,2.5-2.7c0.8-0.8,1.6-1.6,2.5-2.4c0.8-0.7,1.6-1.5,2.5-2.1c0.8-0.7,1.6-1.3,2.5-1.9c0.8-0.6,1.6-1.2,2.5-1.8
c0.8-0.6,1.6-1.1,2.5-1.6c0,0,0.1-0.1,0.1-0.1c0.8-0.5,1.6-0.9,2.4-1.4c0.8-0.5,1.6-0.9,2.5-1.3c0.8-0.4,1.6-0.8,2.5-1.2
c0.8-0.4,1.6-0.7,2.5-1c0.8-0.3,1.6-0.6,2.5-0.9c0.8-0.3,1.6-0.5,2.5-0.8c0.8-0.2,1.6-0.5,2.5-0.7c0.8-0.2,1.6-0.4,2.5-0.6
c0.8-0.2,1.6-0.3,2.5-0.4c0.8-0.1,1.6-0.2,2.5-0.3c0.8-0.1,1.6-0.2,2.5-0.3c0.8-0.1,1.6-0.1,2.5-0.2c0.8,0,1.6-0.1,2.5-0.1
c0.3,0,0.6,0,0.9,0c0.5,0,1.1,0,1.6,0c0.8,0,1.7,0,2.5,0.1c0.8,0.1,1.7,0.1,2.5,0.2c0.8,0.1,1.6,0.2,2.5,0.3
c0.8,0.1,1.6,0.3,2.5,0.4c0.8,0.2,1.7,0.3,2.5,0.5c0.8,0.2,1.7,0.4,2.5,0.6c0.8,0.2,1.7,0.5,2.5,0.7c0.8,0.3,1.6,0.6,2.5,0.9
c0.8,0.3,1.7,0.6,2.5,1c0.3,0.1,0.7,0.3,1,0.4c0.5,0.2,1,0.5,1.5,0.7c0.8,0.4,1.7,0.8,2.5,1.2c0.8,0.4,1.7,0.9,2.5,1.4
c0.8,0.5,1.7,1,2.5,1.5c0.8,0.5,1.7,1.1,2.5,1.6c0.8,0.6,1.7,1.2,2.5,1.8c0.8,0.6,1.7,1.3,2.5,2c0.8,0.7,1.7,1.5,2.5,2.2
c0.4,0.4,0.9,0.8,1.3,1.3c0.4,0.4,0.8,0.8,1.1,1.2c0.9,0.9,1.7,1.8,2.5,2.8c0.9,1,1.7,2.1,2.5,3.2c0.6,0.9,1.3,1.8,1.9,2.7h0.6h2.5
h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5h2.5L386,261.6L386,261.6
C386,261.6,386,261.6,386,261.6z`}
        />
        <path
          fill={col}
          d="M254.2,307.5V356h76.4v35.1c-7.8,5.9-18.1,10.4-28.4,13.4c-10.3,3-21.5,4.5-33.6,4.5
c-12.8,0-24.9-2.5-36.2-7.4c-11.3-4.9-21.2-11.6-29.6-20c-8.4-8.4-15.1-18.3-20-29.6c-4.9-11.3-7.4-23.4-7.4-36.2
c0-12.8,2.5-24.9,7.4-36.2c1.1-2.6,2.3-5,3.6-7.5h-53.6c-4.6,13.9-6.9,28.4-6.9,43.7c0,19.6,3.8,38.1,11.3,55.5
c7.5,17.4,17.8,32.5,30.7,45.5c12.9,13,28.1,23.2,45.5,30.7c4.9,2.1,9.8,3.9,14.9,5.5c4.2,1.3,17.7,5.1,40.6,5.8
c15,0.5,33.4,1.1,56.3-5.3c6.4-1.8,17.9-5,31.4-12.4c0,0,5.4-3,29.3-19v-25v-89.7H254.2z"
        />
      </g>
    </svg>
  );
};

export default MediaHubLogo;
