import GroupRepresentation from "@keycloak/keycloak-admin-client/lib/defs/groupRepresentation";
import UserRepresentation from "@keycloak/keycloak-admin-client/lib/defs/userRepresentation";
import { ExtendedGroupRepresentationSchema } from "./ExtendedGroupRepresentation";

interface ExtendedUserRepresentation extends UserRepresentation {
  assignedToGroup?: boolean;
  group?: GroupRepresentation[];
  requirePasswordless?: boolean;
  requirePasswordUpdate?: boolean;
  password: string;
}

export const ExtendedUserRepresentationSchema = {
  $id: "5gmediahub/user.json#",
  errorMessage: {
    properties: {
      email: "Email must match format 'user@example.com'",
      firstName: "Name should be alphabetical characters or hyphen (-) only.",
      groups: "Groups are required",
      lastName: "Name should be alphabetical characters or hyphen (-) only.",
      password: "Password must be at least 8 characters."
    }
  },
  properties: {
    assignedToGroup: {
      $id: "#/properties/assignedToGroup",
      type: "boolean"
    },
    email: {
      $id: "#/properties/email",
      format: "email",
      // pattern: "/^((?!\\.)[\\w_.]*[^.])(@\\w+)(\\.\\w+(\\.\\w+)?[^.\\W])$",
      // pattern: "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w)+$",
      type: "string"
    },
    firstName: {
      $id: "#/properties/firstName",
      pattern: "^[a-zA-Z-]*$",
      type: "string"
    },
    groups: {
      items: ExtendedGroupRepresentationSchema,
      type: "array",
    },
    lastName: {
      $id: "#/properties/lastName",
      pattern: "^[a-zA-Z-]*$",
      type: "string"
    },
    password: {
      $id: "#/properties/password",
      minLength: 8,
      type: "string"
    }
  },
  required: [
    "email",
    "password"
  ],
  type: "object"
};

export const UserRepresentationSchema = {
  errorMessage: {
    properties: {
      email: "Email must match format 'user@example.com'",
      firstName: "Name should be alphabetical characters or hyphen (-) only.",
      lastName: "Name should be alphabetical characters or hyphen (-) only."
    }
  },
  properties: {
    assignedToGroup: {
      $id: "#/properties/assignedToGroup",
      type: "boolean"
    },
    email: {
      $id: "#/properties/email",
      format: "email",
      type: "string"
    },
    enabled: {
      $id: "#/properties/enabled",
      type: "boolean"
    },
    firstName: {
      $id: "#/properties/firstName",
      pattern: "^[a-zA-Z-]*$",
      type: "string"
    },
    lastName: {
      $id: "#/properties/lastName",
      pattern: "^[a-zA-Z-]*$",
      type: "string"
    },
  },
  required: [
    "email"
  ],
  type: "object"
};

export default ExtendedUserRepresentation;
