import useNotification from "../../hooks/useNotification";
import { NotificationType } from "../../providers/NotificationProvider";
import checkIcon from "../../assets/check.svg";
import errorIcon from "../../assets/error.svg";
import infoIcon from "../../assets/info.svg";
import warningIcon from "../../assets/warning.svg";
import Toast from "../Toast";

const Notification = () => {
  const { notifications } = useNotification();

  const toastList = notifications.map((notification) => {
    let colour = "#fff";
    let icon;
    switch (notification.type) {
      case NotificationType.Success:
        colour = "#5cb85c";
        icon = checkIcon;
        break;
      case NotificationType.Info:
        colour = "#5bc0de";
        icon = infoIcon;
        break;
      case NotificationType.Warning:
        colour = "#f0ad4e";
        icon = warningIcon;
        break;
      case NotificationType.Error:
        colour = "#d9534f";
        icon = errorIcon;
        break;
      default:
        colour = "#000";
        icon = infoIcon;
        break;
    }
    return {
      callback: notification.callback,
      callbackTitle: notification.callbackTitle,
      colour,
      description: notification.message,
      icon,
      id: notification.id,
      title: NotificationType[notification.type],
      type: notification.type
    };
  });

  return (
    <Toast toastList={toastList} position="bottom-right" />
  );
};

export default Notification;
