import Keycloak from "keycloak-js";

// eslint-disable-next-line new-cap
const keycloak = Keycloak({
  clientId: `${process.env.REACT_APP_CLIENT_ID}`,
  realm: `${process.env.REACT_APP_AUTH_REALM}`,
  url: `${process.env.REACT_APP_AUTH_SERVER_ADDRESS}`
});

keycloak.onTokenExpired = () => keycloak.updateToken(30);

export default keycloak;
