import GroupRepresentation from "@keycloak/keycloak-admin-client/lib/defs/groupRepresentation";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import {
  Box, CheckBox, Heading, Tab
} from "grommet";
import useAdminClient from "../../../hooks/useAdminClient";
import useNotification from "../../../hooks/useNotification";
import useLoader from "../../../hooks/useLoader";
import { NotificationType } from "../../../providers/NotificationProvider";
import Page from "../../../components/Page";
import {
  Accordion,
  AccordionPanel,
  Button, Fieldset, Form, FormField, Select, Tabs, TextInput
} from "../../../components/StyledComponents";
import { ExtendedGroupRepresentation } from "../../../types/ExtendedGroupRepresentation";

const DefaultGroup: ExtendedGroupRepresentation = {
  name: ""
};

const CreateGroupPage = () => {
  const navigate = useNavigate();
  const adminClient = useAdminClient();
  const { addNotification } = useNotification();

  const [loader, showLoader, hideLoader] = useLoader();

  const [group, setGroup] = useState<ExtendedGroupRepresentation>(DefaultGroup);
  const [groups, setGroups] = useState<Array<GroupRepresentation>>([]);

  useEffect(() => {
    if (group.subgroup === true && groups.length === 0) {
      adminClient.groups.find()
        .then((_groups) => setGroups(_groups))
        .catch((err) => {
          const error = err as AxiosError;
          if (error.response) {
            addNotification(`Load available groups: ${error.response.statusText}`);
          } else {
            addNotification("Unable to load available groups");
          }
        });
    }
  }, [group.subgroup]);

  const submitGroup = async () => {
    try {
      showLoader();
      const g: GroupRepresentation = {
        name: group.name
      };
      let response;
      if (group.subgroup === true && group.parent && group.parent.id) {
        response = adminClient.groups.setOrCreateChild({ id: group.parent?.id }, g);
      } else {
        response = await adminClient.groups.create(g);
      }
      const { id } = response as any;
      hideLoader();
      addNotification("Group created successfully.", NotificationType.Success);
      if (id && id.length > 0) {
        navigate(`/groups/${id}`);
      }
    } catch (err) {
      hideLoader();
      const error = err as AxiosError;
      if (error.response) {
        addNotification(`Create group: ${error.response.statusText}`);
      } else {
        addNotification("Unable to create group.");
      }
    }
  };

  const onChange = (value: any) => {
    setGroup(value);
  };

  return (
    <Page
      title="Create Group"
      locationHeader={[
        {
          name: "Groups",
          path: "/groups"
        },
        {
          name: "Create"
        }
      ]}
    >
      <Box width="100%" flex align="center">
        <Form
          value={group}
          onChange={onChange}
          onSubmit={submitGroup}
        >
          <Fieldset>
            <Heading level="4">Basic information</Heading>
            <FormField name="name" label="Group Name" required>
              <TextInput name="name" />
            </FormField>

          </Fieldset>
          <Fieldset>
            <Accordion pad="none">
              <AccordionPanel label="Advanced Settings">
                <Box pad={{ vertical: "medium" }}>
                  <Tabs>
                    <Tab title="Subgroups">
                      <Box
                        pad={{ vertical: "medium" }}
                        flex
                        wrap
                        justify="start"
                        align="start"
                        direction="row"
                        gap="medium"
                      >
                        <FormField name="subgroup" label="Is subgroup?">
                          <CheckBox name="subgroup" label="Make subgroup" />
                        </FormField>
                        {
                          (group.subgroup === true && groups.length > 0) && (
                            <FormField name="parent" label="Parent Group">
                              <Select
                                name="parent"
                                options={groups}
                                labelKey="name"
                                valueKey={{ key: "name" }}
                              />
                            </FormField>
                          )
                        }
                      </Box>
                    </Tab>
                  </Tabs>
                </Box>
              </AccordionPanel>
            </Accordion>
          </Fieldset>
          <Box direction="row" gap="medium" justify="center" pad={{ top: "large" }}>
            <Button type="submit" primary label="Submit" />
          </Box>
        </Form>
      </Box>
      {loader}
    </Page>
  );
};

export default CreateGroupPage;
