import KeycloakAdminClient from "@keycloak/keycloak-admin-client";
import { SetStateAction } from "react";
import { DecisionStrategy, Logic } from "@keycloak/keycloak-admin-client/lib/defs/policyRepresentation";
// eslint-disable-next-line import/no-unresolved
import mem from "mem";
import PublicAPI from "../PublicAPI";

const refreshTokenFn = async () => {
  const refreshToken = localStorage.getItem("5gmediahub.umm.refresh_token");

  if (!refreshToken) {
    return {
      access_token: undefined,
      refresh_token: undefined
    };
  }

  try {
    const params = new URLSearchParams();
    params.append("client_id", `${process.env.REACT_APP_CLIENT_ID}`);
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", refreshToken);
    const response = await PublicAPI.post("/realms/5GMediaHUB/protocol/openid-connect/token", params);

    localStorage.setItem("5gmediahub.umm.access_token", response.data.access_token);
    localStorage.setItem("5gmediahub.umm.refresh_token", response.data.refresh_token);

    return {
      access_token: response.data.access_token,
      refresh_token: response.data.refresh_token
    };
  } catch (err) {
    return {
      access_token: undefined,
      refresh_token: undefined
    };
  }
};

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge: 10000
});

export const handleArrayObjectChange = (event: any, obj: any, index: number,
  arrayName: string, fn: SetStateAction<any>, key?: string) => {
  const arr = ((obj as any)[`${arrayName}`] as Array<any>).slice();
  if (key) {
    arr[index][key] = event.target.value;
  } else {
    arr[index] = event.target.value;
  }

  fn((prev: any) => ({
    ...prev,
    [arrayName]: arr
  }));
};

export const addElementToArray = (obj: Object, array: string, fn: SetStateAction<any>) => {
  let arr = (obj as any)[array];
  if (arr) {
    arr.push({});
  } else {
    arr = [{}];
  }

  fn((prev: any) => ({
    ...prev,
    [array]: arr
  }));
};

export const removeElementFromArray = (obj: Object, array: string, index: number, fn: SetStateAction<any>) => {
  const arr = (obj as any)[array];
  if (arr) {
    arr.splice(index, 1);

    fn((prev: any) => ({
      ...prev,
      [array]: arr,
    }));
  }
};

export const emailMask = [
  {
    placeholder: "user",
    regexp: /^[\w\-_.]+$/
  },
  { fixed: "@" },
  {
    placeholder: "example",
    regexp: /^[\w\-_]+$/
  },
  { fixed: "." },
  {
    placeholder: "com",
    regexp: /^[\w-_.]+$/
  },
];

// eslint-disable-next-line no-shadow
export enum Month {
  JAN = 0,
  FEB = 1,
  MAR = 2,
  APR = 3,
  MAY = 4,
  JUN = 5,
  JUL = 6,
  AUG = 7,
  SEP = 8,
  OCT = 9,
  NOV = 10,
  DEC = 11
}

export const mapDate = (date: Date) => {
  const m = Month[date.getMonth()];

  return {
    day: date.getDate(),
    month: m,
    time: `${date.getHours()}:${date.getMinutes()}`
  };
};

export const setupAuthorisation = async (id: string, adminClient: KeycloakAdminClient) => {
  const createRoleAndPolicy = async (clientId: string, roleName: string) => {
    await adminClient.clients.createRole({
      clientRole: true,
      id: clientId,
      name: roleName,
    });
    const role = await adminClient.clients.findRole({
      id: clientId,
      roleName,
    });
    const policy = await adminClient.clients.createPolicy({
      id: clientId,
      type: "role"
    }, {
      decisionStrategy: DecisionStrategy.UNANIMOUS,
      logic: Logic.POSITIVE,
      name: `Only ${roleName}`,
      roles: [{
        id: role.id,
        required: true,
      }
      ],
      type: "role"
    } as any);

    return {
      policyId: policy.id,
      roleId: role.id
    };
  };

  const ownerPolicy = await adminClient.clients.findPolicyByName({
    id,
    name: "Only Owner Policy"
  });
    // Setup roles and create role-based policies
  const onlyAdmin = await createRoleAndPolicy(id, "Administrator");
  const onlyDev = await createRoleAndPolicy(id, "Developer");
  const onlyUser = await createRoleAndPolicy(id, "User");

  // Set up System Administrator composite
  const onlyAdminRep = await adminClient.roles.findOneById({ id: `${onlyAdmin.roleId}` });
  const adminComp = await adminClient.roles.findOneByName({ name: "System Administrator" });
  await adminClient.roles.createComposite({ roleId: `${onlyAdminRep?.id}` }, [adminComp as any]);
  // await adminClient.roles.createComposite({ roleId: `${adminComp.id}` }, [onlyAdminRep]);

  // Set up Developer composite
  const devRoleRep = await adminClient.roles.findOneById({ id: `${onlyDev.roleId}` });
  const realmDevRole = await adminClient.roles.findOneByName({ name: "Developer" });
  await adminClient.roles.createComposite({ roleId: `${devRoleRep?.id}` }, [realmDevRole as any]);

  // Set up User composite - the user role should always be the minimum access level assigned
  // so it should be added to the "User" realm-level composite
  const userRoleRep = await adminClient.roles.findOneById({ id: `${onlyUser.roleId}` });
  const realmUserRole = await adminClient.roles.findOneByName({ name: "User" });
  await adminClient.roles.createComposite({ roleId: `${realmUserRole?.id}` }, [userRoleRep as any]);

  // Create aggregate policies
  const onlyValidUsers = await adminClient.clients.createPolicy({
    id,
    type: "aggregate"
  }, {
    decisionStrategy: DecisionStrategy.AFFIRMATIVE,
    logic: Logic.POSITIVE,
    name: "Only Valid Users",
    policies: [
      `${onlyAdmin.policyId}`,
      `${onlyDev.policyId}`,
      `${onlyUser.policyId}`
    ],
    type: "aggregate"
  });
  const onlyDevAndAdmin = await adminClient.clients.createPolicy({
    id,
    type: "aggregate"
  }, {
    decisionStrategy: DecisionStrategy.AFFIRMATIVE,
    logic: Logic.POSITIVE,
    name: "Only Admin and Developer",
    policies: [
      `${onlyAdmin.policyId}`,
      `${onlyDev.policyId}`
    ],
    type: "aggregate"
  });
    /* const onlyOwnerAdmin = */await adminClient.clients.createPolicy({
    id,
    type: "aggregate"
  }, {
    decisionStrategy: DecisionStrategy.AFFIRMATIVE,
    logic: Logic.POSITIVE,
    name: "Only Owner and Administrators Policy",
    policies: [
      `${onlyAdmin.policyId}`,
      `${ownerPolicy.id}`,
    ],
    type: "aggregate"
  });

  // Create permissions
  await adminClient.clients.createPermission({
    id,
    type: "scope"
  }, {
    decisionStrategy: DecisionStrategy.UNANIMOUS,
    logic: Logic.POSITIVE,
    name: "Resource CREATE permission",
    policies: [`${onlyDevAndAdmin.id}`],
    scopes: ["create"]
  });
  await adminClient.clients.createPermission({
    id,
    type: "scope"
  }, {
    decisionStrategy: DecisionStrategy.UNANIMOUS,
    logic: Logic.POSITIVE,
    name: "Resource VIEW permission",
    policies: [`${onlyValidUsers.id}`],
    scopes: ["view"]
  });

  // Create the base resource
  await adminClient.clients.createResource({ id }, {
    name: "base",
    ownerManagedAccess: false,
    scopes: [
      { name: "create" },
      { name: "delete" },
      { name: "manage" },
      { name: "view" },
      { name: "view-private" },
    ],
    type: "urn:resource:base",
  });

  // Add realm management roles to the client service account
  const serviceAccUser = await adminClient.clients.getServiceAccountUser({ id });
  const serviceAccId = `${serviceAccUser.id}`;

  const backendAppRole = await adminClient.roles.findOneByName({ name: "Backend App" });
  await adminClient.users.addRealmRoleMappings({
    id: serviceAccId,
    roles: [{
      id: `${backendAppRole?.id}`,
      name: `${backendAppRole?.name}`
    }]
  });
};
