import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import useNotification from "../../hooks/useNotification";
import "./index.css";

// eslint-disable-next-line no-shadow
export enum NotificationType {
  Success = 0,
  Info = 1,
  Warning = 2,
  Error = 3
}

interface IToast {
  callback?: () => any;
  callbackTitle?: string;
  id: number;
  title: string;
  description: string;
  colour: string;
  icon: string;
  type: NotificationType;
}

interface IToastProps {
  position: string;
  toastList: Array<IToast>;
}

const Toast = (props: IToastProps) => {
  const { toastList, position } = props;
  const [list, setList] = useState(toastList);
  const { removeNotification } = useNotification();

  useEffect(() => {
    setList(toastList);
  }, [toastList, list]);

  return (
    <ul className={`notification-container ${position}`}>
      <AnimatePresence initial={false}>
        {
          list.map((toast) => (
            <motion.li
              layout="position"
              initial={{
                opacity: 0,
                scale: 0.8,
                x: 300
              }}
              animate={{
                opacity: 0.8,
                scale: 1,
                x: 0
              }}
              exit={{
                opacity: 0,
                scale: 0.8,
                x: -150
              }}
              transition={{
                damping: 40,
                stiffness: 500,
                type: "spring"
              }}
              whileHover={{
                opacity: 1,
                transition: { duration: 0.2 }
              }}
              key={toast.id}
              className={`notification toast ${position}`}
              style={{ backgroundColor: toast.colour }}
            >
              <div className="notification-image">
                <img src={toast.icon} alt="" />
              </div>
              <div className="notification-area">
                <p className="notification-title">{toast.title}</p>
                <p className="notification-message">{toast.description}</p>
                {toast.callback
                  && (
                    <button type="button" className="notification-callback" onClick={toast.callback}>
                      {toast.callbackTitle}
                    </button>
                  )}
              </div>
              <div className="button-area">
                <button onClick={() => removeNotification(toast.id)} type="button">
                  Close
                </button>
              </div>
            </motion.li>
          ))
        }
      </AnimatePresence>
    </ul>
  );
};

export default Toast;
