import React, { useState } from "react";
import FullPageLoader from "../../components/FullPageLoader";

const useLoader = (): [JSX.Element, () => void, () => void] => {
  const [loading, setLoading] = useState(false);

  return [
    <FullPageLoader show={loading} />,
    () => setLoading(true),
    () => setLoading(false)
  ];
};

export default useLoader;
