/* eslint-disable @typescript-eslint/no-unused-vars */
import GroupRepresentation from "@keycloak/keycloak-admin-client/lib/defs/groupRepresentation";
import UserRepresentation from "@keycloak/keycloak-admin-client/lib/defs/userRepresentation";
import { AxiosError } from "axios";
import {
  Box, Grid, Heading, Pagination, TableBody, TableCell, TableHeader, TableRow
} from "grommet";
import { FormPreviousLink, Subtract } from "grommet-icons";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import GroupCard from "../../../components/Cards/GroupCard";
import Modal from "../../../components/Modal";
import Page from "../../../components/Page";
import SkeletonItem, { SkeletonContainer } from "../../../components/SkeletonItem";
import {
  Button,
  Container,
  Fieldset,
  Form, FormField, Link, Table, TextInput
} from "../../../components/StyledComponents";
import { Tab, Tabs } from "../../../components/Tabs";
import useAdminClient from "../../../hooks/useAdminClient";
import useLoader from "../../../hooks/useLoader";
import useNotification from "../../../hooks/useNotification";
import { NotificationType } from "../../../providers/NotificationProvider";
import { Administrator } from "../../../types/RoleTypes";
import authorisedFunction from "../../../utils/AuthorisedFunction";

const DefaultGroup: GroupRepresentation = {
  name: ""
};

interface GroupParams {
  id: string;
}

interface ITabProps {
  id: string;
}

export const EditGroupPageSkeleton = () => (
  <div style={{
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%"
  }}
  >
    <SkeletonItem width="320px" height="40px" />
    <SkeletonContainer width="60%">
      <SkeletonItem height="40px" />
      <div style={{
        display: "flex",
        justifyContent: "space-between",
      }}
      >
        <SkeletonItem width="120px" height="36px" />
        <SkeletonItem width="120px" height="36px" />
      </div>
    </SkeletonContainer>
  </div>
);

const SubgroupsTab = (props: ITabProps) => {
  const [groups, setGroups] = useState<Array<GroupRepresentation>>([]);
  const adminClient = useAdminClient();
  const { addNotification } = useNotification();
  const { id } = props;

  useEffect(() => {
    adminClient.groups.findOne({ id })
      .then((_group) => {
        if (_group?.subGroups && _group.subGroups.length > 0) {
          setGroups(_group.subGroups);
        }
      })
      .catch((err) => {
        const error = err as AxiosError;
        if (error.response) {
          addNotification(`Load Group: ${error.response.statusText}`);
        } else {
          addNotification("Unable to load Group");
        }
      });
  }, []);

  return (
    <Container>
      {
        groups.length === 0 ? (
          "No subgroups available"
        ) : (
          <Grid
            fill
            gap="medium"
            justifyContent="start"
          >
            {groups.map((group) => (
              <GroupCard group={group} />
            ))}
          </Grid>
        )
      }
    </Container>
  );
};

const SettingsTab = (props: ITabProps) => {
  const adminClient = useAdminClient();
  const { addNotification } = useNotification();
  const [group, setGroup] = useState<GroupRepresentation>(DefaultGroup);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { id } = props;
  const [loader, showLoader, hideLoader] = useLoader();
  const navigate = useNavigate();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    adminClient.groups.findOne({ id })
      .then((_group) => setGroup(_group as any))
      .catch((err) => {
        const error = err as AxiosError;
        if (error.response) {
          addNotification(`Load Group: ${error.response.statusText}`);
        } else {
          addNotification("Unable to load Group");
        }
      })
      .finally(() => setReady(true));
  }, []);

  const onChange = (value: any) => {
    setGroup(value);
  };

  const submit = async () => {
    try {
      showLoader();
      await adminClient.groups.update({ id: `${group.id}` }, group);
      addNotification("Group updated successfully.", NotificationType.Success);
    } catch (err) {
      hideLoader();
      const error = err as AxiosError;
      if (error.response) {
        addNotification(`Update group: ${error.response.statusText}`);
      } else {
        addNotification("Unable to update group.");
      }
    }
  };

  const deleteGroup = async () => {
    try {
      showLoader();
      await adminClient.groups.del({ id });
      navigate("/groups");
    } catch (err) {
      const error = err as AxiosError;
      if (error.response) {
        addNotification(`Delete Group : ${error.response.statusText}`);
      } else {
        addNotification("Unable to delete Group");
      }
    }
    hideLoader();
  };

  return (
    <>
      {
        !ready && (
          <SkeletonContainer width="60%">
            <SkeletonItem height="40px" />
            <div style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            >
              <SkeletonItem width="120px" height="36px" />
              <SkeletonItem width="120px" height="36px" />
            </div>
          </SkeletonContainer>
        )
      }
      {ready && (
        <Form value={group} onChange={onChange} onSubmit={submit}>
          <Fieldset disabled={!authorisedFunction([Administrator])}>
            <Heading level="4">Basic information</Heading>
            <FormField name="name" label="Group Name" required>
              <TextInput name="name" />
            </FormField>
            {authorisedFunction([Administrator])
              && (
                <Box direction="row" justify="between" margin={{ top: "medium" }}>
                  <Button label="Delete group" onClick={() => setShowDeleteModal(true)} />
                  <Button type="submit" primary label="Save changes" />
                </Box>
              )}
            <Modal
              confirm
              show={showDeleteModal}
              setShow={setShowDeleteModal}
              children="Are you sure you want to delete this group?"
              confirmFn={() => deleteGroup()}
            />
          </Fieldset>
          {loader}
        </Form>
      )}
    </>
  );
};

const MembersTab = (props: ITabProps) => {
  const navigate = useNavigate();
  const membersPerPage = 10;
  const adminClient = useAdminClient();
  const { addNotification } = useNotification();
  const { id } = props;
  const [members, setMembers] = useState<Array<UserRepresentation>>([]);
  const [page, setPage] = useState(1);
  const [loader, showLoader, hideLoader] = useLoader();
  const [member, setMember] = useState({ email: "" });
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState<UserRepresentation | undefined>(undefined);
  const [showMemberDeleteModal, setShowMemberDeleteModal] = useState(false);

  const fetchMembers = () => {
    adminClient.groups.listMembers({
      first: membersPerPage * (page - 1),
      id,
      max: membersPerPage
    })
      .then((_members) => setMembers(_members))
      .catch((err) => {
        const error = err as AxiosError;
        if (error.response) {
          addNotification(`Load Group Members: ${error.response.statusText}`);
        } else {
          addNotification("Unable to load Group Members");
        }
      });
  };

  const onChange = (value: any) => {
    setMember(value);
  };

  const resetForm = () => {
    setMember({ email: "" });
  };

  const submitMember = async () => {
    try {
      showLoader();
      const users = await adminClient.users.find({ email: member.email });

      if (users.length > 0) {
        await adminClient.users.addToGroup({ groupId: id, id: `${users[0].id}` });
        setShowMemberModal(false);
        addNotification("User added to group successfully.", NotificationType.Success);
      } else {
        addNotification("No user found with the provided email.");
      }
    } catch (err) {
      hideLoader();
      const error = err as AxiosError;
      if (error.response) {
        addNotification(`Add member to group: ${error.response.statusText}`);
      } else {
        addNotification("Unable to add member to group.");
      }
    } finally {
      hideLoader();
      fetchMembers();
    }
  };

  const removeMember = async (userId: string) => {
    try {
      showLoader();
      await adminClient.users.delFromGroup({ groupId: id, id: userId });
      if (page !== 1) {
        setPage(1);
      } else {
        fetchMembers();
      }
      addNotification("Successfully removed member from group", NotificationType.Success);
    } catch (err) {
      const error = err as AxiosError;
      if (error.response) {
        addNotification(`Remove group member: ${error.response.statusText}`);
      } else {
        addNotification("Unable to remove group member");
      }
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    fetchMembers();
  }, [page]);

  return (
    <Container>
      {
        (members.length === 0 && page === 1)
          ? (
            "No members in group"
          ) : (
            <>
              <Table width="100%">
                <TableHeader>
                  <TableRow>
                    <TableCell scope="col" border="bottom">
                      <strong>Username</strong>
                    </TableCell>
                    <TableCell scope="col" border="bottom">
                      <strong>First Name</strong>
                    </TableCell>
                    <TableCell scope="col" border="bottom">
                      <strong>Last Name</strong>
                    </TableCell>
                    {
                      authorisedFunction([Administrator])
                      && (
                        <TableCell scope="col" border="bottom">
                          <strong>Actions</strong>
                        </TableCell>
                      )
                    }
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {
                    members.map((_member) => (
                      <TableRow key={`${_member.id}`}>
                        <TableCell scope="row">{_member.username}</TableCell>
                        <TableCell scope="row">{_member.firstName}</TableCell>
                        <TableCell scope="row">{_member.lastName}</TableCell>
                        {
                          authorisedFunction([Administrator])
                          && (
                            <TableCell scope="row">
                              <Button
                                label="Remove"
                                onClick={() => {
                                  setMemberToDelete(_member);
                                  setShowMemberDeleteModal(true);
                                }}
                              />
                            </TableCell>
                          )
                        }
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
              <Pagination
                alignSelf="end"
                margin="small"
                page={page}
                onChange={({ page: current }) => setPage(current)}
                numberItems={-1}
              />
            </>
          )
      }
      {
        authorisedFunction([Administrator])
        && <Button label="Add member" onClick={() => setShowMemberModal(true)} />
      }
      <Modal
        confirm
        show={showMemberDeleteModal}
        confirmFn={() => removeMember(`${memberToDelete?.id}`)}
        setShow={setShowMemberDeleteModal}
        children={`Are you sure you want to remove ${memberToDelete?.email} from the group?`}
      />
      <Modal
        confirm
        confirmLabel="Submit"
        rejectLabel="Cancel"
        show={showMemberModal}
        setShow={setShowMemberModal}
        onClose={resetForm}
        children={(
          <Form value={member} onChange={onChange} className="width-100">
            <Fieldset>
              <Heading level="4">Add group member</Heading>
              <FormField name="email" label="Email" required>
                <TextInput name="email" />
              </FormField>
            </Fieldset>
          </Form>
        )}
        confirmFn={() => submitMember()}
      />
      {loader}
    </Container>
  );
};

const GroupEditPage = () => {
  const { id } = useParams<any>();

  if (!id) {
    return (
      <Navigate to="/groups" />
    );
  }

  return (
    <Page
      title="Group Edit"
      locationHeader={[
        {
          name: "Groups",
          path: "/groups"
        },
        {
          name: "Edit"
        }
      ]}
    >
      <Box width="100%" flex align="center">
        <Tabs>
          <Tab title="Settings"><SettingsTab id={id} /></Tab>
          <Tab title="Members"><MembersTab id={id} /></Tab>
          <Tab title="Subgroups"><SubgroupsTab id={id} /></Tab>
        </Tabs>
      </Box>
    </Page>
  );
};

export default GroupEditPage;
