/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  CheckBox,
  Heading,
} from "grommet";

import { useState, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import UserRepresentation from "@keycloak/keycloak-admin-client/lib/defs/userRepresentation";
import RoleRepresentation from "@keycloak/keycloak-admin-client/lib/defs/roleRepresentation";

import { AxiosError } from "axios";

import CredentialRepresentation from "@keycloak/keycloak-admin-client/lib/defs/credentialRepresentation";
import ClientRepresentation from "@keycloak/keycloak-admin-client/lib/defs/clientRepresentation";
import API from "../../../API";
import {
  Accordion,
  AccordionPanel,
  Button,
  Container,
  Fieldset,
  Form, FormField, InfoText, MaskedInput, Select, TextInput
} from "../../../components/StyledComponents";
import Page from "../../../components/Page";
import useAdminClient from "../../../hooks/useAdminClient";
import useNotification from "../../../hooks/useNotification";
import RoleAssignment from "../../../components/RoleAssignment";
import { emailMask } from "../../../utils/utils";
import { NotificationType } from "../../../providers/NotificationProvider";
import useLoader from "../../../hooks/useLoader";
import Modal from "../../../components/Modal";
import { UserRepresentationSchema } from "../../../types/ExtendedUserRepresentation";
import validate from "../../../Validate";
import { Administrator } from "../../../types/RoleTypes";
import authorisedFunction from "../../../utils/AuthorisedFunction";
import Tabs, { Tab } from "../../../components/Tabs";
import SkeletonItem, { SkeletonContainer } from "../../../components/SkeletonItem";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IParams {
  id: string;
}

export const DefaultUser: UserRepresentation = {
  clientRoles: [],
  createdTimestamp: 0,
  email: "",
  firstName: "",
  id: "",
  lastName: "",
  username: "",
};

const defaultUser = DefaultUser;
defaultUser.username = "";

interface IUserTabProps {
  id: string;
}

export const EditUserPageSkeleton = () => (
  <div style={{
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%"
  }}
  >
    <SkeletonItem width="320px" height="40px" />
    <SkeletonContainer width="60%">
      <SkeletonItem height="40px" />
      <SkeletonItem height="40px" />
      <SkeletonItem height="40px" />
      <SkeletonItem height="75px" />
      <div style={{
        display: "flex",
        justifyContent: "space-between",
      }}
      >
        <SkeletonItem width="120px" height="36px" />
        <SkeletonItem width="120px" height="36px" />
      </div>
    </SkeletonContainer>
  </div>
);

const SettingsTab = (props: IUserTabProps) => {
  const navigate = useNavigate();
  const adminClient = useAdminClient();
  const { addNotification } = useNotification();
  const { id } = props;
  const [user, setUser] = useState<UserRepresentation>(defaultUser);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loader, showLoader, hideLoader] = useLoader();
  const [errors, setErrors] = useState<any>({});

  const onChange = (value: any) => {
    setUser(value);
  };

  useEffect(() => {
    setErrors(validate(user, UserRepresentationSchema));
  }, [user]);

  useEffect(() => {
    adminClient.users.findOne({ id })
      .then((_user) => setUser(_user as any))
      .catch((err) => {
        const error = err as AxiosError;
        if (error.response) {
          addNotification(`Load Client: ${error.response.statusText}`);
        } else {
          addNotification("Unable to load Client");
        }
      });
  }, []);

  const deleteUser = async () => {
    try {
      showLoader();
      await adminClient.users.del({ id });
      navigate("/users");
    } catch (err) {
      const error = err as AxiosError;
      if (error.response) {
        addNotification(`Delete User : ${error.response.statusText}`);
      } else {
        addNotification("Unable to delete User");
      }
    }
    hideLoader();
  };

  const saveChanges = async () => {
    try {
      showLoader();
      await adminClient.users.update({ id }, user);
      addNotification("User updated successfully", NotificationType.Success);
    } catch (err) {
      const error = err as AxiosError;
      if (error.response) {
        addNotification(`Update user : ${error.response.statusText}`);
      } else {
        addNotification("Unable to update user");
      }
    }
    hideLoader();
  };

  return (
    <Form value={user} onChange={onChange} onSubmit={saveChanges}>
      <Fieldset disabled={!authorisedFunction([Administrator])}>
        <Heading level="4">Basic information</Heading>
        <FormField name="email" label="Email" required error={errors.email}>
          <MaskedInput name="email" mask={emailMask} />
        </FormField>
        <FormField name="firstName" label="First Name" error={errors.firstName}>
          <TextInput name="firstName" />
        </FormField>
        <FormField name="lastName" label="Last Name" error={errors.lastName}>
          <TextInput name="lastName" />
        </FormField>
      </Fieldset>
      <Fieldset disabled={!authorisedFunction([Administrator])}>
        <Accordion pad="none">
          <AccordionPanel label="Advanced Settings">
            <Box pad={{ vertical: "medium" }}>
              <Tabs>
                <Tab title="Account Options">
                  <Box
                    pad={{ vertical: "medium" }}
                    flex
                    wrap
                    justify="start"
                    align="start"
                    direction="row"
                    gap="medium"
                  >
                    <FormField name="enabled">
                      <CheckBox label="Enabled" name="enabled" />
                    </FormField>
                  </Box>
                </Tab>
              </Tabs>
            </Box>
          </AccordionPanel>
        </Accordion>
      </Fieldset>
      {
        authorisedFunction([Administrator])
        && (
          <Box direction="row" justify="between" margin={{ top: "medium" }}>
            <Button label="Delete user" onClick={() => setShowDeleteModal(true)} />
            <Button type="submit" primary label="Save changes" disabled={Object.keys(errors).length !== 0} />
          </Box>
        )
      }
      <Modal
        confirm
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        children="Are you sure you want to delete this user?"
        confirmFn={() => deleteUser()}
      />
      {loader}
    </Form>
  );
};

const SecurityTab = (props: IUserTabProps) => {
  const defaultCredential = {
    confirmation: "",
    password: ""
  };
  const adminClient = useAdminClient();
  const { addNotification } = useNotification();
  const { id } = props;
  const [loader, showLoader, hideLoader] = useLoader();
  const [passwordForm, setPasswordForm] = useState(defaultCredential);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [credentials, setCredentials] = useState<Array<CredentialRepresentation>>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initPasswordlessEnabled, setInitPasswordlessEnabled] = useState(false);
  const [passwordlessEnabled, setPasswordlessEnabled] = useState(false);
  const [initOtpEnabled, setInitOtpEnabled] = useState(false);
  const [otpEnabled, setOtpEnabled] = useState(false);
  const onChange = (value: any) => {
    setPasswordForm(value);
  };

  const submitPassword = async () => {
    try {
      showLoader();
      const data = {
        temporary: true,
        type: "password",
        value: passwordForm.password
      };
      await adminClient.users.resetPassword({
        credential: data,
        id: `${id}`
      });
      addNotification("Password updated successfully", NotificationType.Success);
    } catch (err) {
      const error = err as AxiosError;
      if (error.response) {
        addNotification(`Update password: ${error.response.statusText}`);
      } else {
        addNotification("Unable to update password");
      }
    } finally {
      hideLoader();
    }
  };

  const updateCredentials = async () => {
    try {
      const response = await API.get(`/users/${id}/credentials`);
      setCredentials(response.data);
      let pwEnabled = response.data.findIndex((val: any) => val.type === "webauthn-passwordless") !== -1;
      let isOtpEnabled = response.data.findIndex((val: any) => val.type === "otp") !== -1;

      if (!pwEnabled || !isOtpEnabled) {
        const userRes = await API.get(`/users/${id}`);
        if (userRes.data.requiredActions && userRes.data.requiredActions.length > 0) {
          if (userRes.data.requiredActions.contains("CONFIGURE_TOTP")) {
            isOtpEnabled = true;
          }
          if (userRes.data.requiredActions.contains("webauthn-register-passwordless")) {
            pwEnabled = true;
          }
        }
      }

      setInitPasswordlessEnabled(pwEnabled);
      setPasswordlessEnabled(pwEnabled);
      setInitOtpEnabled(isOtpEnabled);
      setOtpEnabled(isOtpEnabled);
    } catch (err) {
      const error = err as AxiosError;
      if (error.response) {
        addNotification(`Load user credentials: ${error.response.statusText}`);
      } else {
        addNotification("Unable to load user credentials");
      }
    }
  };

  useEffect(() => {
    updateCredentials();
  }, []);

  const submitPasswordlessChange = async () => {
    let updated = false;
    if (passwordlessEnabled && !initPasswordlessEnabled) {
      try {
        const res = await API.get(`/users/${id}`);
        const user = res.data;
        user.requiredActions.push("webauthn-register-passwordless");
        await API.put(`/users/${id}`, user);
        addNotification("User will be asked to setup passwordless auth on next login.", NotificationType.Success);
        updated = true;
      } catch (err) {
        const error = err as AxiosError;
        if (error.response) {
          addNotification(`Add passwordless auth: ${error.response.statusText}`);
        } else {
          addNotification("Unable to enable passwordless authentication.");
        }
      }
    } else if (!passwordlessEnabled && initPasswordlessEnabled) {
      // First get the credential ID
      const cred = credentials.find((val) => val.type === "webauthn-passwordless");
      if (cred) {
        try {
          await API.delete(`/users/${id}/credentials/${cred.id}`);
          // Then should GET credentials again and update values
          addNotification("Passwordless authentication removed.", NotificationType.Success);
          updated = true;
        } catch (err) {
          const error = err as AxiosError;
          if (error.response) {
            addNotification(`Disable passwordless auth: ${error.response.statusText}`);
          } else {
            addNotification("Unable to remove passwordless authentication.");
          }
        }
      }
    }

    if (updated) {
      await updateCredentials();
    }
  };

  const submitOtpChange = async () => {
    if (otpEnabled && !initOtpEnabled) {
      try {
        const res = await API.get(`/users/${id}`);
        const user = res.data;
        user.requiredActions.push("CONFIGURE_TOTP");
        await API.put(`/users/${id}`, user);
        addNotification("User will be asked to setup OTP on next login.", NotificationType.Success);
      } catch (err) {
        const error = err as AxiosError;
        if (error.response) {
          addNotification(`Add OTP auth: ${error.response.statusText}`);
        } else {
          addNotification("Unable to enable OTP authentication.");
        }
      }
    } else if (!otpEnabled && initOtpEnabled) {
      const cred = credentials.find((val) => val.type === "otp");
      if (cred) {
        try {
          await API.delete(`/users/${id}/credentials/${cred.id}`);
          addNotification("OTP authentication removed.", NotificationType.Success);
        } catch (err) {
          const error = err as AxiosError;
          if (error.response) {
            addNotification(`Disable OTP auth: ${error.response.statusText}`);
          } else {
            addNotification("Unable to remove OTP authentication.");
          }
        }
      }
    }
  };

  return (
    <Container>
      <Box
        fill
        gap="medium"
      >
        <Accordion>
          <AccordionPanel label="Password">
            <Form value={passwordForm} onChange={onChange} onSubmit={submitPassword} style={{ width: "100%" }}>
              <FormField name="password" label="New Password" required>
                <TextInput name="password" type="password" />
              </FormField>
              <FormField name="confirmation" label="Confirm new password" required>
                <TextInput name="confirmation" type="password" />
              </FormField>
              <InfoText>
                Any password change made here will be a temporary password. User
                will be prompted to change their password upon next login.
              </InfoText>
              <Box margin={{ top: "medium" }}>
                <Button type="submit" primary label="Update password" />
              </Box>
            </Form>
          </AccordionPanel>
        </Accordion>
        <Accordion>
          <AccordionPanel label="Passwordless">
            <Box pad={{ vertical: "medium" }}>
              <CheckBox
                label="Passwordless authentication"
                checked={passwordlessEnabled}
                onChange={(event) => setPasswordlessEnabled(event.target.checked)}
              />
              <InfoText>
                Enable/disable passwordless authentication.
                Passwordless authentication allows users to login using a device or a security key.
                If enabled here, users will be required to setup passwordless authentication upon next login.
              </InfoText>
              <Box margin={{ top: "medium" }}>
                <Button
                  type="submit"
                  primary
                  label="Save changes"
                  disabled={initPasswordlessEnabled === passwordlessEnabled}
                  onClick={submitPasswordlessChange}
                />
              </Box>
            </Box>
          </AccordionPanel>
        </Accordion>
        <Accordion>
          <AccordionPanel label="One-time Passcode">
            <Box pad={{ vertical: "medium" }}>
              <CheckBox
                label="One-time Passcode"
                checked={otpEnabled}
                onChange={(event) => setOtpEnabled(event.target.checked)}
              />
              <InfoText>
                This will require the user to set up a one-time passcode using
                a mobile authenticator upon next login.
              </InfoText>
              <Box margin={{ top: "medium" }}>
                <Button
                  type="submit"
                  primary
                  label="Save changes"
                  disabled={initOtpEnabled === otpEnabled}
                  onClick={() => submitOtpChange()}
                />
              </Box>
            </Box>
          </AccordionPanel>
        </Accordion>
        {loader}
      </Box>
    </Container>
  );
};

const RolesTab = (props: IUserTabProps) => {
  const adminClient = useAdminClient();
  const { addNotification } = useNotification();
  const { id } = props;
  const [userRoles, setUserRoles] = useState<Array<RoleRepresentation>>([]);
  const [availableRoles, setAvailableRoles] = useState<Array<RoleRepresentation>>([]);
  const [effectiveRoles, setEffectiveRoles] = useState<Array<RoleRepresentation>>([]);
  const [clients, setClients] = useState<Array<ClientRepresentation>>([]);
  const [filteredClients, setFilteredClients] = useState<Array<ClientRepresentation>>([]);
  const [activeClient, setActiveClient] = useState<ClientRepresentation | string>("");
  const [originalRoles, setOriginalRoles] = useState<Array<RoleRepresentation>>([]);
  const [loader, showLoader, hideLoader] = useLoader();

  useEffect(() => {
    if (typeof activeClient === "object" && activeClient.clientId) {
      adminClient.users.listClientRoleMappings({
        clientUniqueId: `${activeClient.id}`,
        id
      })
        .then((_roles) => {
          setOriginalRoles(_roles);
          setUserRoles(_roles);
        })
        .catch((err) => {
          const error = err as AxiosError;
          if (error.response) {
            addNotification(`Load User Roles: ${error.response.statusText}`);
          } else {
            addNotification("Unable to load User Roles");
          }
        });
    }
  }, [activeClient]);

  useEffect(() => {
    if (typeof activeClient === "object" && activeClient.clientId) {
      adminClient.users.listCompositeClientRoleMappings({
        clientUniqueId: `${activeClient.id}`,
        id
      })
        .then((_roles) => setEffectiveRoles(_roles))
        .catch((err) => {
          const error = err as AxiosError;
          if (error.response) {
            addNotification(`Load User Roles: ${error.response.statusText}`);
          } else {
            addNotification("Unable to load User Roles");
          }
        });
    }
  }, [activeClient]);

  useEffect(() => {
    if (typeof activeClient === "object" && activeClient.clientId) {
      adminClient.users.listAvailableClientRoleMappings({
        clientUniqueId: `${activeClient.id}`,
        id
      })
        .then((_roles) => {
          setAvailableRoles(_roles.filter((_role) => _role.name !== "uma_protection"));
        })
        .catch((err) => {
          const error = err as AxiosError;
          if (error.response) {
            addNotification(`Load User Roles: ${error.response.statusText}`);
          } else {
            addNotification("Unable to load User Roles");
          }
        });
    }
  }, [activeClient]);

  useEffect(() => {
    adminClient.clients.find()
      .then((_clients) => {
        setClients(_clients.filter((cl) => cl.attributes && cl.attributes.clientType === "5gmediahub"));
      })
      .catch((err) => {
        const error = err as AxiosError;
        if (error.response) {
          addNotification(`Load Clients: ${error.response.statusText}`);
        } else {
          addNotification("Unable to load Clients");
        }
      });
  }, []);

  useEffect(() => {
    setFilteredClients(clients);
  }, [clients]);

  const saveChanges = async () => {
    if (typeof activeClient === "object") {
      const removed: RoleRepresentation[] = [];
      const added: RoleRepresentation[] = [];

      // Find the roles that have been removed
      originalRoles.forEach((_role) => {
        const found = userRoles.find((_newRole) => JSON.stringify(_newRole) === JSON.stringify(_role));
        if (!found) {
          removed.push(_role);
        }
      });

      // Find the roles that have been added
      userRoles.forEach((_role) => {
        const found = originalRoles.find((_newRole) => JSON.stringify(_newRole) === JSON.stringify(_role));
        if (!found) {
          added.push(_role);
        }
      });

      // Delete roles that have been removed
      const removedRoles = [];
      for (let i = 0; i < removed.length; i += 1) {
        removedRoles.push({
          id: `${removed[i].id}`,
          name: `${removed[i].name}`
        });
      }
      try {
        if (removedRoles.length > 0) {
          showLoader();
          await adminClient.users.delClientRoleMappings({
            clientUniqueId: `${activeClient.id}`,
            id,
            roles: removedRoles
          });
          addNotification("Roles removed successfully.", NotificationType.Success);
        }
      } catch (err) {
        const error = err as AxiosError;
        if (error.response) {
          addNotification(`Delete User Roles: ${error.response.statusText}`);
        } else {
          addNotification("Unable to delete User Roles");
        }
      }

      // Add roles that have been added
      const addedRoles = [];
      for (let i = 0; i < added.length; i += 1) {
        addedRoles.push({
          id: `${added[i].id}`,
          name: `${added[i].name}`
        });
      }

      try {
        if (addedRoles.length > 0) {
          await adminClient.users.addClientRoleMappings({
            clientUniqueId: `${activeClient.id}`,
            id,
            roles: addedRoles
          });
          addNotification("Roles added successfully.", NotificationType.Success);
        }
      } catch (err) {
        const error = err as AxiosError;
        if (error.response) {
          addNotification(`Add User Roles: ${error.response.statusText}`);
        } else {
          addNotification("Unable to add User Roles");
        }
      }

      hideLoader();
    }
  };

  return (
    <Container>
      <Box
        fill
        gap="medium"
      >
        <Select
          options={filteredClients}
          labelKey="clientId"
          placeholder="Select a client..."
          searchPlaceholder="Search clients"
          emptySearchMessage="No clients found"
          value={activeClient}
          dropHeight="medium"
          onChange={(option) => setActiveClient(option.value)}
          onSearch={(text) => {
            const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
            const regexp = new RegExp(escapedText, "i");
            const options = clients.filter((o) => regexp.test(`${o.clientId}`));
            setFilteredClients(options);
          }}
        />
        <Box flex direction="row" fill gap="medium">
          <RoleAssignment
            assignedRoles={userRoles}
            setAssignedRoles={setUserRoles}
            availableRoles={availableRoles}
            setAvailableRoles={setAvailableRoles}
            effectiveRoles={effectiveRoles}
          />
        </Box>
        <Button label="Save changes" onClick={() => saveChanges()} disabled={originalRoles === userRoles} />
      </Box>
      {loader}
    </Container>
  );
};

const EditUserPage = () => {
  const adminClient = useAdminClient();
  const { addNotification } = useNotification();
  const { id } = useParams<any>();
  const [user, setUser] = useState<string>("");
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (id) {
      adminClient.users.findOne({ id })
        .then((_user) => setUser(`${_user?.username}`))
        .catch((err) => {
          const error = err as AxiosError;
          if (error.response) {
            addNotification(`Load User details: ${error.response.statusText}`);
          } else {
            addNotification("Unable to load User details");
          }
        })
        .finally(() => setReady(true));
    }
  }, []);

  if (id === undefined) {
    return (<Navigate to="/users" replace />);
  }

  return (
    <Page
      locationHeader={[
        {
          name: "Users",
          path: "/users"
        },
        {
          name: "Edit"
        },
        {
          name: user.length > 0 ? `${user}` : "Unknown"
        }
      ]}
      title={user.length > 0 ? `User Edit (${user})` : "User Edit"}
    >
      {
        !ready && <EditUserPageSkeleton />
      }
      {
        ready && (
          <Box width="100%" flex align="center">

            <Tabs>
              <Tab title="Profile">
                <SettingsTab id={id} />
              </Tab>
              {authorisedFunction([Administrator])
                && (
                  <Tab title="Authentication">
                    <SecurityTab id={id} />
                  </Tab>
                )}
              {authorisedFunction([Administrator])
                && (
                  <Tab title="Roles">
                    <RolesTab id={id} />
                  </Tab>
                )}
            </Tabs>
          </Box>
        )
      }
    </Page>
  );
};

export default EditUserPage;
