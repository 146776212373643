import RoleRepresentation from "@keycloak/keycloak-admin-client/lib/defs/roleRepresentation";
import { Dispatch, SetStateAction } from "react";
import { useDrop } from "react-dnd";
import {
  CardHeading, RoleList, RoleListContainer, RoleListItem
} from "../StyledComponents";
import Item from "./Item";

interface IRoleAssignmentProps {
  assignedRoles: RoleRepresentation[];
  availableRoles: RoleRepresentation[];
  effectiveRoles: RoleRepresentation[];
  setAssignedRoles: Dispatch<SetStateAction<RoleRepresentation[]>>;
  setAvailableRoles: Dispatch<SetStateAction<RoleRepresentation[]>>;
}

// eslint-disable-next-line no-shadow
export enum RoleType {
  Assigned = "ASSIGNED",
  Available = "AVAILABLE",
}

const RoleAssignment = (props: IRoleAssignmentProps) => {
  const {
    assignedRoles, availableRoles, setAssignedRoles, setAvailableRoles, effectiveRoles
  } = props;

  const [{ isOver: isOverAssigned }, assignedRolesRef] = useDrop({
    accept: RoleType.Available,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    })
  });
  const [{ isOver: isOverAvailable }, availableRolesRef] = useDrop({
    accept: RoleType.Assigned,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    })
  });

  const moveRole = (item: { index: number; type: string; }) => {
    if (item && item.type === RoleType.Available) {
      // Set role as assigned
      setAssignedRoles((_roles) => [..._roles, availableRoles[item.index]]);
      setAvailableRoles((_roles) => _roles.filter((_, index) => index !== item.index));
    } else {
      // Remove assigned role
      setAvailableRoles((_roles) => [..._roles, assignedRoles[item.index]]);
      setAssignedRoles((_roles) => _roles.filter((_, index) => index !== item.index));
    }
  };

  return (
    <>
      <RoleListContainer>
        <CardHeading level="4">
          Available Roles
        </CardHeading>
        <RoleList ref={availableRolesRef} highlight={isOverAvailable}>
          {
            availableRoles.map((role, index) => (
              <Item role={role} key={`${role.id}`} index={index} onDrop={moveRole} type={RoleType.Available} />
            ))
          }
        </RoleList>
      </RoleListContainer>
      <RoleListContainer>
        <CardHeading level="4">
          Assigned Roles
        </CardHeading>
        <RoleList ref={assignedRolesRef} highlight={isOverAssigned}>
          {
            assignedRoles.map((role, index) => (
              <Item role={role} key={`${role.id}`} index={index} onDrop={moveRole} type={RoleType.Assigned} />
            ))
          }
        </RoleList>
      </RoleListContainer>
      <RoleListContainer>
        <CardHeading level="4">
          Effective Roles
        </CardHeading>
        <RoleList>
          {
            effectiveRoles.map((role) => (
              <RoleListItem>
                <div>
                  {role.name}
                </div>
              </RoleListItem>
            ))
          }
        </RoleList>
      </RoleListContainer>
    </>
  );
};

export default RoleAssignment;
