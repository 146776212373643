/* eslint-disable react/no-array-index-key */
import { AxiosError } from "axios";
import {
  Box, CheckBox, Heading, Tab
} from "grommet";

import { SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../components/Page";
import {
  Accordion,
  AccordionPanel,
  Button, CodeText, Divider, Fieldset, Form, FormError, FormField, InfoText, Select, Tabs, TextInput
} from "../../components/StyledComponents";
import useAdminClient from "../../hooks/useAdminClient";
import useLoader from "../../hooks/useLoader";
import useNotification from "../../hooks/useNotification";
import { NotificationType } from "../../providers/NotificationProvider";
import ExtendedClientRepresentation, { ClientType } from "../../types/ExtendedClientRepresentation";
import { setupAuthorisation } from "../../utils/utils";

const DefaultClient: ExtendedClientRepresentation = {
  attributes: {
    clientType: "5gmediahub",
    login_theme: "5gmediahub",
  },
  authorizationServicesEnabled: true,
  authorizationSettings: {
    allowRemoteResourceManagement: true,
    policies: [{
      name: "Only Owner Policy",
      type: "script-only-owner.js"
    }],
    policyEnforcementMode: "ENFORCING",
    scopes: [
      {
        name: "create"
      }, {
        name: "view"
      }, {
        name: "manage"
      }, {
        name: "delete"
      }]
  },
  bearerOnly: false,
  clientAuthenticatorType: "client-secret",
  clientId: "",
  clientType: ClientType.Confidential,
  consentRequired: false,
  directAccessGrantsEnabled: false,
  enabled: true,
  fullScopeAllowed: true,
  pkceEnabled: false,
  protocol: "openid-connect",
  publicClient: false,
  redirectUris: [""],
  rootUrl: "",
  serviceAccountsEnabled: true,
  standardFlowEnabled: true,
  webOrigins: [""]
};

const CreateClientPage = () => {
  const navigate = useNavigate();
  const [client, setClient] = useState<ExtendedClientRepresentation>(DefaultClient);
  const [valid, setValid] = useState(false);
  const { addNotification } = useNotification();
  const adminClient = useAdminClient();

  const [loader, showLoader, hideLoader] = useLoader();

  const addElementToArray = (obj: Object, array: string, fn: SetStateAction<any>, item?: any) => {
    let arr = (obj as any)[array];
    if (arr) {
      if (item !== undefined) {
        arr.push(item);
      } else {
        arr.push({});
      }
    } else if (item !== undefined) {
      arr = [item];
    } else {
      arr = [{}];
    }

    fn((prev: any) => ({
      ...prev,
      [array]: arr
    }));
  };
  const removeElementFromArray = (obj: Object, array: string, index: number, fn: SetStateAction<any>) => {
    const arr = (obj as any)[array];
    if (arr) {
      arr.splice(index, 1);

      fn((prev: any) => ({
        ...prev,
        [array]: arr
      }));
    }
  };

  const handleArrayObjectChange = (event: any, index: number, array: string, key?: string) => {
    const arr = ((client as any)[`${array}`] as Array<any>).slice();
    if (key) {
      arr[index][key] = event.target.value;
    } else {
      arr[index] = event.target.value;
    }

    setClient((prevClient) => ({
      ...prevClient,
      [array]: arr
    }));
  };

  const submitClient = async () => {
    console.log("Submitting...");
    let resId;
    try {
      // Increment stage each time something is done, and this will
      // make it easier to roll back changes as needed
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const stage = 0;
      showLoader();
      client.attributes = {
        clientType: "5gmediahub",
        "pkce.code.challenge.method": client.pkceEnabled === true ? "S256" : ""
      };
      const c: any = { ...client };
      c.clientType = undefined;
      c.resourceTypes = undefined;
      c.pkceEnabled = undefined;

      // Remove any black Redirect URIs or Web Origins
      const redirectUris = client.redirectUris?.filter((item) => item.trim().length > 0);
      const webOrigins = client.webOrigins?.filter((item) => item.trim().length > 0);
      c.redirectUris = redirectUris;
      c.webOrigins = webOrigins;

      if (!client.authorizationServicesEnabled) {
        c.authorizationSettings = undefined;
      }
      for (let i = c.webOrigins.length - 1; i >= 0; i -= 1) {
        if (typeof c.webOrigins[i] !== "string" || c.webOrigins[i].length === 0) {
          c.webOrigins.splice(i, 1);
        }
      }
      // Delete any keys with undefined values to prevent issues
      Object.keys(c).forEach((key) => c[key] === undefined && delete c[key]);
      const response = await adminClient.clients.create(c);

      resId = response.id;

      // Only do this if authorisation services enabled
      if (client.authorizationServicesEnabled) {
        await setupAuthorisation(response.id, adminClient);
      }

      hideLoader();
      addNotification("Application created successfully.", NotificationType.Success);
      const { id } = response;
      if (id && id.length > 0) {
        navigate(`/apps/${id}`);
      }
    } catch (err) {
      hideLoader();
      const error = err as AxiosError;
      if (error.response) {
        addNotification(`Create Application: ${error.response.statusText}`);
      } else {
        addNotification("Unable to create Application.");
      }

      // In case of any errors occurring during create process, roll back any changes that
      // have been made
      if (resId) {
        await adminClient.clients.del({ id: resId });
      }
    }
  };

  useEffect(() => {
    if (client.webOrigins) {
      if (client.webOrigins.length === 0) {
        const c = client;
        c.webOrigins = [""];
        setClient(c);
      }
    } else {
      const c = client;
      c.webOrigins = [""];
      setClient(c);
    }
  }, [client]);

  useEffect(() => {
    const c = client;
    switch (client.clientType) {
      case ClientType.BearerOnly:
        c.bearerOnly = true;
        c.publicClient = false;
        c.authorizationServicesEnabled = false;
        c.serviceAccountsEnabled = false;
        c.pkceEnabled = false;
        c.webOrigins = [""];
        break;
      case ClientType.Public:
        c.bearerOnly = false;
        c.publicClient = true;
        c.authorizationServicesEnabled = false;
        c.serviceAccountsEnabled = false;
        break;
      case ClientType.Confidential:
        c.bearerOnly = false;
        c.publicClient = false;
        break;
      default:
        break;
    }
    setClient(c);
  }, [client.clientType]);

  const onChange = (value: any) => {
    setClient(value);
  };
  return (
    <Page
      title="Create Application"
      locationHeader={[
        {
          name: "Applications",
          path: "/apps"
        },
        {
          name: "Create"
        }
      ]}
    >
      <Box width="100%" flex align="center">
        <Form
          value={client}
          onChange={onChange}
          onSubmit={submitClient}
          validate="change"
          onValidate={(results) => setValid(results.valid)}
        >
          <Fieldset>
            <Heading level="4">Basic information</Heading>
            <FormField
              name="clientId"
              htmlFor="text-input-clientId"
              label="Client ID"
              required
              validate={(val) => {
                if (val.trim().length === 0 || !(/^(?!-)[a-zA-Z-_]{3,32}(?<!-)$/gm.test(val))) {
                  return (
                    <FormError>
                      Invalid Client ID. Must be in a format similar to
                      {" "}
                      <CodeText>my-client-application</CodeText>
                    </FormError>
                  );
                }
                return undefined;
              }}
            >
              <TextInput id="text-input-clientId" name="clientId" />
              <InfoText>
                The Client ID must be unique, and in a format similar to
                {" "}
                <CodeText>my-client-application</CodeText>
                . It must begin with an alphabetical character and can contain 3-32 alphanumeric
                characters or non-repeating hyphens.
              </InfoText>
            </FormField>
            <FormField name="name" label="Display name">
              <TextInput name="name" placeholder="Add a reader-friendly name" />
            </FormField>
            <FormField name="description" label="Description">
              <TextInput name="description" placeholder="Add a description in less than 140 characters" />
            </FormField>
          </Fieldset>
          <Divider />
          <Fieldset>
            <Heading level="4">Application Properties</Heading>
            <FormField
              name="clientType"
              label="Application Type"
            >
              <Select
                name="clientType"
                options={[ClientType.Public, ClientType.Confidential, ClientType.BearerOnly]}
                defaultValue={client.clientType}
              />
              <InfoText>
                The type of application will determine which settings you can configure from the dashboard.
              </InfoText>
            </FormField>
          </Fieldset>
          <Divider />
          <Fieldset>
            <Heading level="4">Application URIs</Heading>
            <FormField name="rootUrl" label="Root URL">
              <TextInput
                id="text-input-rootUrl"
                name="rootUrl"
                disabled={client.clientType === ClientType.BearerOnly}
              />
            </FormField>
            <FormField
              name="redirectUris"
              label="Allowed Callback URLs"
              required
              validate={(val) => {
                if (client.clientType === ClientType.BearerOnly) {
                  return undefined;
                }
                let validItems = false;
                for (let i = 0; i < val.length; i += 1) {
                  if (val[i].trim().length > 0) {
                    validItems = true;
                  }
                  if (val[i].trim().length === 0 && i === val.length - 1 && !validItems) {
                    return <FormError>At least one valid callback URL must be specified</FormError>;
                  }
                }
                return undefined;
              }}
            >
              <Box gap="small">
                {
                  client.redirectUris?.map((uri, index) => (
                    <Box direction="row" gap="small">
                      <TextInput
                        key={index}
                        value={uri}
                        onChange={(event) => handleArrayObjectChange(event, index, "redirectUris")}
                        disabled={client.clientType === ClientType.BearerOnly}
                      />
                      {
                        (index === (client.redirectUris!.length - 1))
                        && (
                          <Button
                            secondary
                            label="+"
                            onClick={() => addElementToArray(client, "redirectUris", setClient, "")}
                            disabled={client.clientType === ClientType.BearerOnly}
                          />
                        )
                      }
                      {
                        (index !== (client.redirectUris!.length - 1))
                        && (
                          <Button
                            secondary
                            label="-"
                            onClick={() => removeElementFromArray(client, "redirectUris", index, setClient)}
                            disabled={client.clientType === ClientType.BearerOnly}
                          />
                        )
                      }

                    </Box>
                  ))
                }
              </Box>
              <InfoText>
                After the user authenticates, callbacks will only be allowed to any of these URLs.
                Make sure to specify the protocol (e.g.
                {" "}
                <CodeText>https://</CodeText>
                ) or the callback may fail. If you have specified a
                {" "}
                <CodeText>Root URL</CodeText>
                {" "}
                then you may use relative URLs, e.g.
                {" "}
                <CodeText>/login/callback</CodeText>
              </InfoText>
            </FormField>
            <FormField name="webOrigins" label="Allowed Web Origins">
              <Box gap="small">
                {
                  client.webOrigins?.map((uri, index) => (
                    <Box direction="row" gap="small">
                      <TextInput
                        key={`webOrigin[${index}]`}
                        value={uri}
                        disabled={client.clientType === ClientType.BearerOnly}
                        onChange={(event) => handleArrayObjectChange(event, index, "webOrigins")}
                      />
                      {
                        (index === (client.webOrigins!.length - 1))
                        && (
                          <Button
                            secondary
                            label="+"
                            disabled={client.clientType === ClientType.BearerOnly}
                            onClick={() => addElementToArray(client, "webOrigins", setClient, "")}
                          />
                        )
                      }
                      {
                        (index !== (client.webOrigins!.length - 1))
                        && (
                          <Button
                            secondary
                            label="-"
                            disabled={client.clientType === ClientType.BearerOnly}
                            onClick={() => removeElementFromArray(client, "webOrigins", index, setClient)}
                          />
                        )
                      }
                    </Box>
                  ))
                }
              </Box>
              <InfoText>
                Allowed CORS origins. To permit all origins of Valid Callback URLs, add
                {" "}
                <CodeText>+</CodeText>
                . To permit all origins explicitly, use the
                {" "}
                <CodeText>*</CodeText>
                {" "}
                wildcard.
              </InfoText>
            </FormField>
          </Fieldset>
          <Fieldset>
            <Accordion pad="none">
              <AccordionPanel label="Advanced Settings">
                <Box pad={{ vertical: "medium" }}>
                  <Tabs>
                    <Tab title="Grant Types">
                      <Box
                        pad={{ vertical: "medium" }}
                        flex
                        wrap
                        justify="start"
                        align="start"
                        direction="row"
                        gap="medium"
                      >
                        <FormField name="directAccessGrantsEnabled">
                          <CheckBox
                            disabled={client.clientType === ClientType.BearerOnly}
                            label="Password"
                            name="directAccessGrantsEnabled"
                          />
                        </FormField>
                      </Box>
                    </Tab>
                    <Tab title="OAuth">
                      <Box
                        pad={{ vertical: "medium" }}
                        flex
                        wrap
                        justify="start"
                        align="start"
                        direction="row"
                        gap="medium"
                      >
                        <FormField name="authorizationServicesEnabled">
                          <CheckBox
                            disabled={client.clientType !== ClientType.Confidential}
                            name="authorizationServicesEnabled"
                            label="Resource Authorisation"
                          />
                        </FormField>
                        <FormField name="serviceAccountsEnabled">
                          <CheckBox
                            disabled={client.clientType !== ClientType.Confidential}
                            name="serviceAccountsEnabled"
                            label="Service Accounts"
                          />
                        </FormField>
                        <FormField name="consentRequired">
                          <CheckBox
                            name="consentRequired"
                            label="Consent Required"
                          />
                        </FormField>
                      </Box>
                    </Tab>
                    <Tab title="Authentication">
                      <Box
                        pad={{ vertical: "medium" }}
                        flex
                        wrap
                        justify="start"
                        align="start"
                        direction="row"
                        gap="medium"
                      >
                        <FormField name="pkceEnabled">
                          <CheckBox
                            name="pkceEnabled"
                            disabled={client.clientType === ClientType.BearerOnly}
                            label="Proof Key for Code Exchange (PKCE)"
                          />
                        </FormField>
                      </Box>
                    </Tab>
                  </Tabs>
                </Box>
              </AccordionPanel>
            </Accordion>
          </Fieldset>
          <Box direction="row" gap="medium" justify="center">
            <Button type="submit" primary label="Save changes" disabled={!valid} />
          </Box>
        </Form>
      </Box>
      {loader}
    </Page>
  );
};

export default CreateClientPage;
