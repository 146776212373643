import { Box, Grid } from "grommet";
import {
  AppsRounded, Catalog, Group, User
} from "grommet-icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../components/Page";
import useAdminClient from "../../hooks/useAdminClient";
import { Administrator, Developer } from "../../types/RoleTypes";
import authorisedFunction from "../../utils/AuthorisedFunction";
import "./index.css";

const HomePage = () => {
  const adminClient = useAdminClient();
  const navigate = useNavigate();
  const [users, setUsers] = useState(0);
  const [groups, setGroups] = useState(0);
  const [clients, setClients] = useState(0);

  useEffect(() => {
    adminClient.users.count()
      .then((_count) => setUsers(_count));
  }, []);
  useEffect(() => {
    adminClient.groups.count()
      .then((response) => setGroups(response.count));
  }, []);
  useEffect(() => {
    adminClient.clients.find()
      .then((response) => {
        setClients(response.filter((cl) => cl.attributes && cl.attributes.clientType === "5gmediahub").length);
      });
  }, []);

  return (
    <Page
      title="Home"
      locationHeader={[
        {
          name: "User Management Portal"
        },
        {
          name: "Home"
        }
      ]}
    >
      <Box
        margin={{
          vertical: "small",
        }}
      />
      <Box width="100%" flex gap="medium" direction="row" justify="center">
        <Grid
          fill
          columns={["auto", "auto"]}
          gap="medium"
          justifyContent="center"
        >
          <button type="button" className="display-box" onClick={() => navigate("/users")}>
            <div className="display-box-title">
              <User size="32px" />
              <div>Users</div>
            </div>
            <div className="display-box-value">
              {users}
            </div>
          </button>
          <button type="button" className="display-box" onClick={() => navigate("/groups")}>
            <div className="display-box-title">
              <Group size="32px" />
              <div>Groups</div>
            </div>
            <div className="display-box-value">
              {groups}
            </div>
          </button>
          <button
            type="button"
            className="display-box"
            onClick={() => navigate("/apps")}
            disabled={!authorisedFunction([Administrator, Developer])}
          >
            <div className="display-box-title">
              <AppsRounded size="32px" />
              <div>Applications</div>
            </div>
            <div className="display-box-value">
              {clients}
            </div>
          </button>
          <button type="button" className="display-box" onClick={() => window.open("https://docs.netapps-5gmediahub.eu", "_blank")?.focus()}>
            <div className="display-box-title">
              <Catalog size="32px" />
              <div>Documentation</div>
            </div>
            <div className="display-box-value">
              5G
            </div>
          </button>
        </Grid>
      </Box>
    </Page>
  );
};

export default HomePage;
