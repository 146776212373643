import { ReactNode } from "react";
import "./index.css";

interface IProps {
  backgroundColor?: string;
  borderRadius?: string | number;
  boxShadow?: string;
  height?: string | number;
  width?: string | number;
  padding?: string | number;
  paddingTop?: string | number;
  paddingBottom?: string | number;
  paddingRight?: string | number;
  paddingLeft?: string | number;
  margin?: string | number;
  marginBottom?: string | number;
  marginTop?: string | number;
  marginLeft?: string | number;
  marginRight?: string | number;
}

interface IContainerProps extends IProps {
  children ?: ReactNode;
}

export const SkeletonContainer = (props: IContainerProps) => {
  const {
    children, height, width, padding, paddingTop, paddingBottom, paddingRight,
    paddingLeft, margin, marginBottom, marginTop, marginLeft, marginRight,
    borderRadius, backgroundColor, boxShadow
  } = props;

  const bgColour = backgroundColor ? `${backgroundColor}` : "white";
  return (
    <div
      className="skeleton-container"
      style={{
        backgroundColor: bgColour,
        borderRadius,
        boxShadow,
        height,
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        padding,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingTop,
        width
      }}
    >
      {children}
    </div>
  );
};

const SkeletonItem = (props: IProps) => {
  const {
    height, width, padding, paddingTop, paddingBottom, paddingRight,
    paddingLeft, margin, marginBottom, marginTop, marginLeft, marginRight,
    borderRadius, backgroundColor, boxShadow
  } = props;

  const bgColour = backgroundColor ? `${backgroundColor}` : "var(--link-colour)";
  return (
    <div
      className="skeleton-item"
      style={{
        backgroundColor: bgColour,
        borderRadius,
        boxShadow,
        height,
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        padding,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingTop,
        width
      }}
    />
  );
};

export default SkeletonItem;
