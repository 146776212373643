import RoleRepresentation from "@keycloak/keycloak-admin-client/lib/defs/roleRepresentation";
import { useDrag } from "react-dnd";
import { RoleType } from "..";
import { RoleListItem } from "../../StyledComponents";

interface IRoleItemProps {
  index: number;
  role: RoleRepresentation;
  type: RoleType;
  onDrop: (item: {index: number; type: string}) => void;
}

const Item = (props: IRoleItemProps) => {
  const {
    index, role, type, onDrop
  } = props;

  const [, dragRef] = useDrag(
    () => ({
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();

        if (item && dropResult) {
          onDrop(item);
        }
      },
      item: {
        index, type
      },
      type
    }),
    []
  );

  return (
    <RoleListItem ref={dragRef}>
      <div>
        {role.name}
      </div>
    </RoleListItem>
  );
};

export default Item;
