import {
  createContext, ReactNode, useCallback, useEffect, useState
} from "react";

// eslint-disable-next-line no-shadow
export enum NotificationType {
  Success = 0,
  Info = 1,
  Warning = 2,
  Error = 3
}

interface INotificationContextType {
  notifications: Array<INotification>;
  addNotification: (message: string, type?: NotificationType,
    callback?: () => any, callbackTitle?: string) => number;
  removeNotification: (id: number) => void;
}

interface INotification {
  callback?: () => any;
  callbackTitle?: string;
  id: number;
  message: string;
  type: NotificationType;
}

export const NotificationContext = createContext<INotificationContextType>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addNotification: (message: string, type: NotificationType = NotificationType.Error) => -1,
  notifications: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeNotification: (id: number) => { }
});

interface INotificationProviderProps {
  children: ReactNode;
}

export default function NotificationProvider(props: INotificationProviderProps) {
  const { children } = props;
  const [notifications, setNotifications] = useState<Array<INotification>>([]);

  const removeNotification = (id: number) => {
    const index = notifications.findIndex((e) => e.id === id);
    if (index !== -1) {
      notifications.splice(index, 1);
      setNotifications([...notifications]);
    }
  };

  const addNotification = (message: string, type: NotificationType = NotificationType.Error,
    callback?: () => any, callbackTitle = "Retry") => {
    let newId = Math.floor((Math.random() * 100) + 1);
    // eslint-disable-next-line no-loop-func
    while (notifications.findIndex((e) => e.id === newId) !== -1) {
      newId = Math.floor((Math.random() * 100) + 1);
    }
    const notification: INotification = {
      callback,
      callbackTitle,
      id: newId,
      message,
      type,
    };
    notifications.push(notification);
    setNotifications([...notifications]);
    setTimeout(() => {
      removeNotification(newId);
    }, 5000);
    return newId;
  };

  const contextValue = {
    addNotification: useCallback((message: any, type: any,
      callback: any, callbackTitle: any) => addNotification(message, type, callback, callbackTitle), []),
    notifications,
    removeNotification: useCallback((id: any) => removeNotification(id), [])
  };

  useEffect(() => {
    if (notifications.length > 5) {
      notifications.splice(0, 1);
      setNotifications([...notifications]);
    }
  }, [notifications]);

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
}
