/* eslint-disable @typescript-eslint/no-unused-vars */
import GroupRepresentation from "@keycloak/keycloak-admin-client/lib/defs/groupRepresentation";
import { AxiosError } from "axios";
import { Box, Spinner } from "grommet";
import { Add, Search } from "grommet-icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { motion } from "framer-motion";
import { useInfiniteQuery } from "react-query";
import GroupCard from "../../../components/Cards/GroupCard";
import Page from "../../../components/Page";
import { Button, TextInput } from "../../../components/StyledComponents";
import useAdminClient from "../../../hooks/useAdminClient";
import useNotification from "../../../hooks/useNotification";
import authorisedFunction from "../../../utils/AuthorisedFunction";
import { Administrator } from "../../../types/RoleTypes";
import { CardSkeleton } from "../../../components/Cards";
import { MAX_LIST_ITEMS_PER_PAGE } from "../../../constants";
import useElementOnScreen from "../../../hooks/useElementOnScreen";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05
    }
  }
};

const GroupsPage = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotification();
  const adminClient = useAdminClient();
  const [groups, setGroups] = useState<Array<GroupRepresentation>>([]);
  const [ready, setReady] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [filter, setFilter] = useState("");

  const fetchGroups = async (p: number) => {
    if (!p) {
      return {
        groups: [],
        info: {
          page: 1,
          total: 0,
        },
      };
    };
    try {
      const count = await adminClient.groups.count();
      const res = await adminClient.groups.find({
        first: MAX_LIST_ITEMS_PER_PAGE * (p - 1),
        max: MAX_LIST_ITEMS_PER_PAGE,
      });
      return {
        groups: res,
        info: {
          page: p,
          total: count,
        },
      };
    } catch (err) {
      const e = err as AxiosError;
      if (e.response) {
        addNotification(`Load Groups: ${e.response.statusText}`);
      } else {
        addNotification("Unable to load Groups");
      }
    }
    return {
      groups: [],
      info: {
        page: 1,
        total: 0
      },
    };
  };

  const {
    isLoading,
    isError,
    error,
    data,
    fetchNextPage,
    isFetching,
    hasNextPage
  } = useInfiniteQuery(["groups"], ({ pageParam = 1 }) => fetchGroups(pageParam), {
    getNextPageParam: (lastPage) => {
      if (lastPage.info.page * MAX_LIST_ITEMS_PER_PAGE < lastPage.info.total) {
        return lastPage.info.page + 1;
      }
      return false;
    }
  });

  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 1.0
  }, (visible) => {
    if (visible && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  });

  const createGroup = () => {
    navigate("/groups/create");
  };

  return (
    <Page
      title="Groups"
      locationHeader={[
        {
          name: "Groups",
          path: "/groups"
        },
        {
          name: "List"
        }
      ]}
      pageControls={(
        <>
          <div className="input-content">
            <TextInput
              icon={(
                <Search />
              )}
              height="fit-content"
              placeholder="Search..."
              className="search-input"
              value={filter}
              onChange={(_event) => setFilter(_event.target.value)}
            />
          </div>
          {authorisedFunction([Administrator]) && (
            <Button
              primary
              label={(
                <span className="button-label">
                  <span className="label-icon"><Add /></span>
                  {" "}
                  Create group
                </span>
              )}
              className="header-btn-add-new"
              onClick={createGroup}
            />
          )}
        </>
      )}
    >
      <Box
        margin={{
          vertical: "small",
        }}
      />
      <div>
        {
          isLoading
          && (
            <motion.div className="card-grid" variants={container} initial="hidden" animate="show">
              {[...Array(5 * 5)].map(() => <CardSkeleton />)}
            </motion.div>
          )
        }
        {
          (!isLoading && data) && (
            <motion.div className="card-grid" variants={container} initial="hidden" animate="show">
              {
                data.pages.map((gr) => gr.groups.filter((_group) => _group.name?.includes(filter)).map((group) => (
                  <GroupCard group={group} />
                )))
              }
            </motion.div>
          )
        }
        <div className="overflow-box" ref={containerRef}>
          {isFetching && <Spinner />}
        </div>
      </div>
    </Page>
  );
};

export default GroupsPage;
