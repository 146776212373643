import { useEffect, useRef, useState } from "react";

interface IntersectionObserverInit {
  root?: Element | Document | null;
  rootMargin?: string;
  threshold?: number | number[];
}

const useElementOnScreen = (options: IntersectionObserverInit, onVisibilityChange?: (visible: boolean) => any) => {
  const containerRef: any = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const callbackFn = (entries: any) => {
    const [entry] = entries;
    // if (entry.isIntersecting !== isVisible && onVisibilityChange) {
    //   onVisibilityChange(entry.isIntersecting);
    // }
    if (onVisibilityChange) {
      onVisibilityChange(entry.isIntersecting);
    }
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFn, options);
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef, options]);

  return [containerRef, isVisible];
};

export default useElementOnScreen;
