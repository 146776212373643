/* eslint-disable max-len */
interface IProps {
  className?: string;
  colour?: string;
  height?: number;
}

const Delete = (props: IProps) => {
  const defaultWidth = 21;
  const defaultHeight = 27;

  const { className, colour, height } = props;
  const col = colour ?? "#fff";
  const h: string | number = height ?? "1em";
  const w = (typeof h === "number") ? (h / defaultHeight) * defaultWidth : "1em";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 21 27" className={className} preserveAspectRatio="xMidYMid">
      <path fill={col} d="M9,28.5a3.009,3.009,0,0,0,3,3H24a3.009,3.009,0,0,0,3-3v-18H9ZM28.5,6H23.25l-1.5-1.5h-7.5L12.75,6H7.5V9h21Z" transform="translate(-7.5 -4.5)" />
    </svg>

  );
};

export default Delete;
