import axios, { AxiosRequestConfig } from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_UMM_API}`
});

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem("5gmediahub.umm.access_token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default instance;
