import jwtDecode from "jwt-decode";

export default function authorisedFunction(roles?: string[]) {
  const token = localStorage.getItem("5gmediahub.umm.access_token");
  if (!token) {
    return false;
  }
  const decoded: any = jwtDecode(token);
  const resourceAccess = decoded.resource_access;

  const hasResourceRole = (role: string, resource: string) => {
    if (!resourceAccess) {
      return false;
    }

    const access = resourceAccess[resource];
    return !!access && access.roles.indexOf(role) >= 0;
  };

  const isAuthorised = () => {
    if (roles) {
      if (roles.length === 0) {
        return true;
      }
      return roles.some((role) => hasResourceRole(role, "umm-portal"));
    }
    return false;
  };

  const isAuth = isAuthorised();
  return isAuth;
}
