import { ReactKeycloakProvider } from "@react-keycloak/web";
import React from "react";
import { createRoot } from "react-dom/client";
import { AuthClientTokens } from "@react-keycloak/core";
import { Grommet } from "grommet";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider, useDispatch } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import keycloak from "./keycloak";
import AppRouter from "./routes";
import theme from "./theme";
import "./styles.css";
import NotificationProvider from "./providers/NotificationProvider";
import Notification from "./components/Notification";
import AdminClientProvider, { adminClient } from "./providers/AdminClientProvider";
import LoadingPage from "./pages/LoadingPage";
import store from "./store";
import { setToken } from "./features/auth/authSlice";

const OuterApp = () => {
  const dispatch = useDispatch();
  const queryClient = new QueryClient();

  const setTokens = (tokens: AuthClientTokens) => {
    if (tokens.token) {
      localStorage.setItem("5gmediahub.umm.access_token", tokens.token);
      // sessionStorage.setItem("token", tokens.token);
      dispatch(setToken(tokens.token));
      adminClient.accessToken = tokens.token;
      console.log("Set tokens");
    }
    if (tokens.refreshToken) {
      localStorage.setItem("5gmediahub.umm.refresh_token", tokens.refreshToken);
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onTokens={setTokens}
      initOptions={{
        onLoad: "check-sso",
        refreshToken: localStorage.getItem("5gmediahub.umm.refresh_token") ?? undefined,
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
        token: localStorage.getItem("5gmediahub.umm.access_token") ?? undefined,
      }}
      LoadingComponent={<LoadingPage />}
    >
      <AdminClientProvider>
        <NotificationProvider>
          <QueryClientProvider client={queryClient}>
            <DndProvider backend={HTML5Backend}>
              <Grommet theme={theme} full>
                <AppRouter />
                <Notification />
              </Grommet>
            </DndProvider>
          </QueryClientProvider>
        </NotificationProvider>
      </AdminClientProvider>
    </ReactKeycloakProvider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <OuterApp />
    </Provider>
  </React.StrictMode>
);
