import { useKeycloak } from "@react-keycloak/web";
import { motion } from "framer-motion";
import MediaHubLogo from "../icons/MediaHubLogo";
import User from "../icons/User";
import { Administrator, Developer } from "../types/RoleTypes";
import authorisedFunction from "../utils/AuthorisedFunction";
import "./AppBar.css";
import { NavLink } from "./StyledComponents";

const AppBar = () => {
  const { keycloak } = useKeycloak();
  return (
    <motion.nav initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} className="app-bar">
      <div className="menu-logo">
        <MediaHubLogo />
        {/* <img src="/logo-icon.png" width="50px" alt="5GMediaHUB Logo" /> */}
      </div>
      <ul className="menu-items">
        <NavLink
          to="/"
          style={({ isActive }: any) => (isActive ? "active" : undefined)}
        >
          <i className="fas fa-home fa-lg" />
        </NavLink>
        <NavLink
          to="/users"
          style={({ isActive }: any) => (isActive ? "active" : undefined)}
        >
          {/* <i className="fas fa-user fa-lg" /> */}
          <User />
        </NavLink>
        <NavLink to="/groups" style={({ isActive }: any) => (isActive ? "active" : undefined)}>
          <i className="fas fa-users fa-lg" />
        </NavLink>
        {authorisedFunction([Administrator, Developer]) && (
          <NavLink to="/apps" style={({ isActive }: any) => (isActive ? "active" : undefined)}>
            <i className="fas fa-project-diagram fa-lg" />
          </NavLink>
        )}
      </ul>
      <ul className="nav-footer">
        <motion.button
          className="nav-button"
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}
          onClick={() => keycloak.accountManagement()}
        >
          <i className="fas fa-user-circle fa-lg" />
        </motion.button>
        <motion.button
          className="nav-button"
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}
          onClick={() => {
            // Remove tokens from localStorage and logout
            localStorage.removeItem("5gmediahub.umm.access_token");
            localStorage.removeItem("5gmediahub.umm.refresh_token");
            keycloak.logout();
          }}
        >
          <i className="fas fa-sign-out-alt fa-lg" />
        </motion.button>
      </ul>
    </motion.nav>
  );
};

export default AppBar;
