import KcAdminClient from "@keycloak/keycloak-admin-client";
import { createContext, ReactNode } from "react";

interface IAdminClientContextType {
  adminClient: KcAdminClient;
}

export const AdminClientContext = createContext<IAdminClientContextType>({ adminClient: new KcAdminClient() });

interface IAdminClientProviderProps {
  children: ReactNode;
}

const adminClient: KcAdminClient = new KcAdminClient();

export default function AdminClientProvider(props: IAdminClientProviderProps) {
  const { children } = props;

  // adminClient = new KcAdminClient();
  adminClient.setConfig({
    baseUrl: `${process.env.REACT_APP_AUTH_SERVER_ADDRESS}`,
    realmName: "5GMediaHUB"
  });

  const contextValue = {
    adminClient
  };

  return (
    <AdminClientContext.Provider value={contextValue}>
      {children}
    </AdminClientContext.Provider>
  );
}

export { adminClient };
