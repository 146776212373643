import {
  Grommet, Box, Spinner, Paragraph
} from "grommet";
import theme from "../theme";
import "./LoadingPage.css";

const LoadingPage = () => (
  <Grommet theme={theme} full>
    <Box fill align="center" justify="center" className="loading-bg">
      <Spinner message="Loading" color="brand" size="medium" />
      <Paragraph>Connecting to 5GMediaHUB authentication module...</Paragraph>
    </Box>
  </Grommet>
);

export default LoadingPage;
