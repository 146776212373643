import {
  Heading,
  Paragraph,
} from "grommet";

import { Halt } from "grommet-icons";
import Page from "../components/Page";

const UnauthorisedPage = () => (
  <Page title="Unauthorised">
    <Halt size="xlarge" color="black" />
    <Heading margin="small">Unauthorised</Heading>
    <Paragraph margin="none" textAlign="center">
      You do not have the required permissions to view this page. Check
      with administrators.
    </Paragraph>
  </Page>
);

export default UnauthorisedPage;
