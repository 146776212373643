import { useKeycloak } from "@react-keycloak/web";
import { useCallback } from "react";
import { Navigate, useLocation } from "react-router-dom";

const LoginPage = () => {
  const location = useLocation();
  const currentLocationState: any = location.state || {
    from: { pathname: "/" },
  };
  const { keycloak } = useKeycloak();
  const login = useCallback(() => {
    keycloak?.login();
  }, [keycloak]);

  //

  if (keycloak?.authenticated) {
    return <Navigate to={currentLocationState?.from as string} />;
  }
  login();

  return <div>Login</div>;
};

export default LoginPage;
