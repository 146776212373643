/* eslint-disable max-len */
interface IProps {
  className?: string;
  colour?: string;
  height?: number;
}

const User = (props: IProps) => {
  const defaultWidth = 31.5;
  const defaultHeight = 36;
  const { className, colour, height } = props;
  const col = colour ?? "#fff";
  const h: string | number = height ?? "1em";
  const w = (typeof h === "number") ? (h / defaultHeight) * defaultWidth : "1em";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={h}
      viewBox="0 0 31.5 36"
      className={className}
      preserveAspectRatio="xMidYMid"
    >
      <path fill={col} d="M15.75,18a9,9,0,1,0-9-9A9,9,0,0,0,15.75,18Zm6.3,2.25H20.876a12.24,12.24,0,0,1-10.252,0H9.45A9.452,9.452,0,0,0,0,29.7v2.925A3.376,3.376,0,0,0,3.375,36h24.75A3.376,3.376,0,0,0,31.5,32.625V29.7A9.452,9.452,0,0,0,22.05,20.25Z" />
    </svg>

  );
};

export default User;
