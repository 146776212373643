import GroupRepresentation from "@keycloak/keycloak-admin-client/lib/defs/groupRepresentation";
import { AxiosError } from "axios";
import {
  Box, CheckBox, Heading, Tab
} from "grommet";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../../components/Page";
import {
  Accordion,
  AccordionPanel,
  Button, Divider, Fieldset, Form, FormField, InfoText, MaskedInput, Select, Tabs, TextInput
} from "../../../components/StyledComponents";
import useAdminClient from "../../../hooks/useAdminClient";
import useNotification from "../../../hooks/useNotification";
import { NotificationType } from "../../../providers/NotificationProvider";
import ExtendedUserRepresentation,
{ ExtendedUserRepresentationSchema } from "../../../types/ExtendedUserRepresentation";
import { emailMask } from "../../../utils/utils";
import validate from "../../../Validate";

const DefaultUser = {
  email: "",
  enabled: true,
  firstName: "",
  lastName: "",
  password: "",
  requirePasswordless: false,
};

const CreateUserPage = () => {
  const { addNotification } = useNotification();
  const adminClient = useAdminClient();
  const [user, setUser] = useState<ExtendedUserRepresentation>(DefaultUser);
  const [groups, setGroups] = useState<Array<GroupRepresentation>>([]);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (user.assignedToGroup === true && groups.length === 0) {
      adminClient.groups.find()
        .then((_groups) => setGroups(_groups))
        .catch((err) => {
          const error = err as AxiosError;
          if (error.response) {
            addNotification(`Load available groups: ${error.response.statusText}`);
          } else {
            addNotification("Unable to load available groups");
          }
        });
    }
  }, [user.assignedToGroup]);

  const submitUser = async () => {
    try {
      const requiredActions = [];
      if (user.requirePasswordless) {
        requiredActions.push("webauthn-register-passwordless");
      }
      if (user.requirePasswordUpdate) {
        requiredActions.push("UPDATE_PASSWORD");
      }
      const newUser = {
        credentials: [
          {
            temporary: true,
            type: "password",
            value: user.password
          }
        ],
        email: user.email,
        enabled: true,
        firstName: user.firstName,
        lastName: user.lastName,
        requiredActions,
      };
      const { id } = await adminClient.users.create(newUser as any);
      // Add user to selected groups
      if (user.assignedToGroup && user.group && user.group.length > 0) {
        const groupOps = [];
        for (let i = 0; i < user.group.length; i += 1) {
          if (!(user.group[i] === undefined || user.group[i].id === undefined)) {
            groupOps.push(adminClient.users.addToGroup({
              groupId: `${user.group[i].id}`,
              id
            }));
          }
        }
        await Promise.all(groupOps);
      }
      addNotification("User created successfully.", NotificationType.Success);
      navigate(`/users/${id}`);
      setUser(DefaultUser);
    } catch (err) {
      const error = err as AxiosError;
      if (error.response) {
        addNotification(`Create client: ${error.response.statusText}`);
      } else {
        addNotification("Unable to create client.");
      }
    }
  };

  useEffect(() => {
    setErrors(validate(user, ExtendedUserRepresentationSchema));
  }, [user]);

  return (
    <Page
      locationHeader={[
        {
          name: "Users",
          path: "/users"
        },
        {
          name: "Create"
        }
      ]}
      title="Create User"
    >
      <Box flex align="center">
        <Form
          value={user}
          onChange={(nextValue: any) => setUser(nextValue)}
          onReset={() => setUser(DefaultUser)}
          onSubmit={submitUser}
        >
          <Fieldset>
            <Heading level="4">Profile information</Heading>
            <FormField name="email" htmlFor="text-input-id" label="Email" error={errors.email}>
              <MaskedInput id="text-input-id" name="email" mask={emailMask} />
            </FormField>
            <FormField
              name="firstName"
              htmlFor="text-input-id"
              label="First Name"
              error={errors.firstName}
            >
              <TextInput id="text-input-id" name="firstName" />
            </FormField>
            <FormField
              name="lastName"
              htmlFor="text-input-id"
              label="Last Name"
              error={errors.lastName}
            >
              <TextInput id="text-input-id" name="lastName" />
            </FormField>

          </Fieldset>
          <Divider />
          <Fieldset>
            <Heading level="4">Security</Heading>
            <FormField
              name="password"
              htmlFor="text-input-id"
              label="Password"
              error={errors.password}
            >
              <TextInput id="text-input-id" name="password" type="password" />
              <InfoText>
                The password created here is temporary.
                The user will be prompted to update their password on initial login.
              </InfoText>
            </FormField>
          </Fieldset>
          <Fieldset>
            <Accordion pad="none">
              <AccordionPanel label="Advanced Settings">
                <Box pad={{ vertical: "medium" }}>
                  <Tabs>
                    <Tab title="Groups">
                      <Box
                        pad={{ vertical: "medium" }}
                        flex
                        wrap
                        justify="start"
                        align="start"
                        gap="medium"
                      >
                        <FormField name="assignedToGroup" label="Assigned To Group">
                          <CheckBox name="assignedToGroup" label="Assign user to Group" />
                        </FormField>
                        {
                          (user.assignedToGroup === true && groups.length > 0) && (
                            <FormField name="group" label="Groups" error={errors.groups}>
                              <Select
                                name="group"
                                options={groups}
                                labelKey="name"
                                multiple
                                valueKey={{ key: "name" }}
                              />
                            </FormField>
                          )
                        }
                      </Box>
                    </Tab>
                    <Tab title="Authentication">
                      <Box
                        pad={{ vertical: "medium" }}
                        flex
                        wrap
                        justify="start"
                        align="start"
                        gap="medium"
                      >
                        <FormField name="requirePasswordless" label="Passwordless authentication">
                          <CheckBox name="requirePasswordless" label="Require passwordless authentication setup?" />
                        </FormField>
                      </Box>
                    </Tab>
                  </Tabs>
                </Box>
              </AccordionPanel>
            </Accordion>
          </Fieldset>
          <Box direction="row" gap="medium" justify="center" pad={{ top: "large" }}>
            <Button type="submit" primary label="Submit" disabled={Object.keys(errors).length !== 0} />
            <Button type="reset" label="Reset" />
          </Box>
        </Form>
      </Box>
    </Page>
  );
};

export default CreateUserPage;
