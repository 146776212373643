/* eslint-disable max-len */
interface IProps {
  className?: string;
  colour?: string;
  height?: number;
}

const Copy = (props: IProps) => {
  const defaultWidth = 28.5;
  const defaultHeight = 33;

  const { className, colour, height } = props;
  const col = colour ?? "#fff";
  const h: string | number = height ?? "1em";
  const w = (typeof h === "number") ? (h / defaultHeight) * defaultWidth : "1em";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 28.5 33" className={className} preserveAspectRatio="xMidYMid">
      <path
        d="M24,1.5H6a3.009,3.009,0,0,0-3,3v21H6V4.5H24Zm4.5,6H12a3.009,3.009,0,0,0-3,3v21a3.009,3.009,0,0,0,3,3H28.5a3.009,3.009,0,0,0,3-3v-21A3.009,3.009,0,0,0,28.5,7.5Zm0,24H12v-21H28.5Z"
        transform="translate(-3 -1.5)"
        fill={col}
      />
    </svg>

  );
};

export default Copy;
